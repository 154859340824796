import betalogo from "assets/images/icons/beta.svg";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import "./academicTranscript.css";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { axiosService } from "services/axios.service";
import sha256 from "crypto-js/sha256";
import { QRCodeCanvas } from "qrcode.react";
import "jspdf-autotable";

const FinalTranscript = ({ downloadType, pdfData, data }) => {
  const studentId = pdfData?.personalData?.studentId;

  const applicationNo = pdfData?.programDetails[0]?.applicationNo;
  const hashValueData = sha256(`${"Final-Transcript"}-${studentId}`)
    .toString()
    .substring(0, 20);

  const url = `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`;

  const [load, setLoad] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const qrCodeRef = useRef(null);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  useEffect(() => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current.querySelector("canvas");
      if (canvas) {
        setQrCodeData(canvas.toDataURL());
      }
    }
  }, []);
  const generatePDF = async () => {
    const doc = new jsPDF();
    const header = () => {
      doc.addImage(beta, "PNG", 10, 10, doc.internal.pageSize.width - 20, 20);
    };
    const addStudentInfo = () => {
      const pageWidth = doc.internal.pageSize.width;
      doc.setFontSize(15).setFont("helvetica", "bold");
      const title = "FINAL TRANSCRIPT";
      const titleX = (pageWidth - doc.getTextWidth(title)) / 2;
      doc
        .text(title, titleX, 40)
        .setLineWidth(0.5)
        .setDrawColor(200)
        .line(10, 45, pageWidth - 10, 45);

      const studentInfo = [
        {
          label: "Student Name: ",
          value: `${pdfData?.personalData?.firstName || ""} ${
            pdfData?.personalData?.lastName || ""
          }`,
        },
        {
          label: "Address: ",
          value: `${pdfData?.mailingAddress?.streetNo || ""} ${
            pdfData?.mailingAddress?.street || ""
          }`,
        },
        {
          label: "Program: ",
          value: pdfData?.programDetails?.[0]?.program || "-",
          wrapWidth: 75,
        },
        {
          label: "Start Date: ",
          value: pdfData?.programDetails?.[0]?.fromDate || "-",
        },
      ];

      const rightInfo = [
        {
          label: "Student No: ",
          value: pdfData?.personalData?.studentId || "-",
        },
        { label: "D.O.B: ", value: pdfData?.personalData?.dateOfBirth || "-" },
        {
          label: "Program Hours: ",
          value: pdfData?.programDetails?.[0]?.programDuration || "-",
        },
        {
          label: "End Date: ",
          value: pdfData?.programDetails?.[0]?.toDate || "-",
        },
      ];

      let leftYPos = 55;
      let rightYPos = 55;

      const renderText = (label, value, x, y, wrapWidth = 170) => {
        const lineHeight = 6;
        doc.setFontSize(11).setFont("helvetica", "bold").text(label, x, y);
        const wrappedText = doc.splitTextToSize(value, wrapWidth);
        doc.setFont("helvetica", "normal").text(wrappedText, x + 40, y);
        return y + lineHeight * wrappedText.length;
      };

      studentInfo.forEach(({ label, value, wrapWidth }) => {
        leftYPos = renderText(label, value, 10, leftYPos, wrapWidth || 150);
      });

      const rightMargin = pageWidth / 2 + 10;
      rightInfo.forEach(({ label, value }) => {
        rightYPos = renderText(label, value, rightMargin, rightYPos);
      });
      const finalY = Math.max(leftYPos, rightYPos) + 5;
      doc
        .setLineWidth(0.5)
        .setDrawColor(200)
        .line(10, finalY, pageWidth - 10, finalY);
    };

    const qrcode = () => {
      doc.addImage(
        qrCodeData,
        "PNG",
        17.5,
        doc.internal.pageSize.height - 18,
        17,
        17
      );
    };
    const addWatermark = () => {
      if (downloadType === "view") {
        const watermarkText = "Sample";
        doc.setFontSize(100);
        doc.setTextColor(199, 200, 204);
        const centerX = doc.internal.pageSize.width / 2;
        const centerY = doc.internal.pageSize.height / 2 + 50;
        doc.text(watermarkText, centerX, centerY, {
          align: "center",
          angle: 45,
        });
      }
    };

    const renderFooter = () => {
      doc.addImage(
        footer,
        "PNG",
        10,
        doc.internal.pageSize.height - 20,
        doc.internal.pageSize.width - 20,
        20
      );
      if (downloadType === "download") {
        qrcode();
      }
    };

    let rows = data.map((row) => ({
      moduleCode: row.module?.module?.moduleCode,
      moduleName: row.module?.module?.moduleName,
      markPercentage: row.markPercentage,
      grade: row.grade,
      result: row.result,
      status: row.status,
    }));
    const tableColumns = [
      { title: "Code", dataKey: "moduleCode" },
      { title: "Course", dataKey: "moduleName" },
      { title: "%", dataKey: "markPercentage" },
      { title: "Grade", dataKey: "grade" },
      { title: "Passed", dataKey: "result" },
      { title: "Completed", dataKey: "status" },
    ];

    const addTable = () => {
      doc.autoTable({
        startY: 100,
        head: [tableColumns.map((col) => col.title)],
        body: rows.map((row) => [
          row.moduleCode,
          row.moduleName,
          row.markPercentage,
          row.grade,
          row.result,
          row.status,
        ]),
        theme: "grid",

        headStyles: {
          fillColor: [19, 16, 16],
          textColor: [255, 255, 255],
          fontSize: 11,
          fontStyle: "bold",
        },

        didDrawPage: function (data) {
          header();
          addWatermark();
          renderFooter();
        },
        margin: { top: 40, bottom: 30 },
      });
    };
    const additionalContent = () => {
      const footerHeight = 30;
      const additionalContentHeight = 120;
      const pageHeight = doc.internal.pageSize.height;
      const availableHeight =
        pageHeight - doc.lastAutoTable.finalY - footerHeight;

      if (availableHeight < additionalContentHeight) {
        doc.addPage();

        header();
        addWatermark();
        renderFooter();

        doc
          .setFontSize(11)
          .setFont("helvetica", "normal")
          .setTextColor(0, 0, 0);
        let marginTop = 40;
        renderAdditionalContent(marginTop);
      } else {
        let marginTop = doc.lastAutoTable.finalY + 20;
        doc.setTextColor(0, 0, 0);
        renderAdditionalContent(marginTop);
      }
    };
    const renderAdditionalContent = (marginTop) => {
      const marginLeft = 10; // Left margin
      const lineHeight = 8;

      doc.setFontSize(11).setFont("helvetica", "normal").setTextColor(0, 0, 0); // Set text color to black

      // First section
      doc.text(
        "To successfully complete the program at Beta College of Business & Technology, a student must:",
        marginLeft,
        marginTop
      );

      doc.text(
        "Receive a minimum of 70% in each of the modules.",
        marginLeft,
        marginTop + 10
      );

      // Grading notes and key information
      const finalGradeText = "Grading Notes and Key Information:";
      doc.setFont("helvetica", "bold");
      doc.text(finalGradeText, marginLeft, marginTop + 30); // Aligned to left

      const gradingKey = [
        `Diploma Semester/Term Start Date - ${
          pdfData?.programDetails?.[0]?.fromDate || "-"
        }`,
        `Diploma Semester/Term End Date - ${
          pdfData?.programDetails?.[0]?.toDate || "-"
        }`,
        `Diploma Term/Sem End Grade: - ${"-"}`,
        `Attendance Recorded: - ${"-"}`,
        `Remarks: - ${"-"}`,
        `Diploma Final Grade:  - ${"-"}`,
        `Credential:- ${"-"}`,
        `NOTE: Students must achieve an overall average of 60%, with no mark below 50% on all evaluation methods`,
      ];

      let yPos = marginTop + 40;

      gradingKey.forEach((line) => {
        doc
          .setFontSize(11)
          .setFont("helvetica", "normal")
          .setTextColor(0, 0, 0);
        doc.text(line, marginLeft, yPos); // Aligned to left
        yPos += lineHeight;
      });

      // Signature and issue date
      const signatureY = yPos + 20;
      doc.text(
        "Authorized Signature of Institute Representative:",
        marginLeft,
        signatureY
      );
      doc.text("(Register/Director)", marginLeft, signatureY + 10);
      doc.text(
        "Issue Date: " + formatDate(new Date()),
        marginLeft,
        signatureY + 20
      );
    };

    addStudentInfo();
    addTable();
    additionalContent();

    if (downloadType === "view") {
      const pdfBlob = doc.output("blob");
      const pdfDataUri = URL.createObjectURL(pdfBlob);
      const newWindow = window?.open();
      newWindow?.document?.write(
        '<iframe width="100%" height="100%" src="' + pdfDataUri + '"></iframe>'
      );
      setLoad(false);
    } else if (hashValueData) {
      try {
        const pdfBlob = doc.output("blob");
        const fileName = `${
          downloadType === "view"
            ? "Final-Transcript.sample"
            : "Final-Transcript"
        }_${studentId}.pdf`;

        const pdfFile = new File([pdfBlob], fileName, {
          type: "application/pdf",
        });

        const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

        const formData = new FormData();
        formData.append("file", pdfFile);

        const response = await axiosService.postFile(url, pdfFile, {});

        const byteCharacters = atob(response.data.data.body);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const urlBlob = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = urlBlob;
        a.download = "final-transcript.pdf";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(urlBlob);

        setLoad(false);
      } catch (error) {
        console.error("Error:", error);
        setLoad(false);
      }
    }
  };
  useEffect(() => {
    if (
      (downloadType === "download" && qrCodeData) ||
      downloadType === "view"
    ) {
      generatePDF();
    }
  }, [downloadType, qrCodeData]);
  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}

      <div ref={qrCodeRef} style={{ display: "none" }}>
        <QRCodeCanvas value={url} size={256} />
      </div>
    </span>
  );
};

export default FinalTranscript;
