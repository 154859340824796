import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import crmlogo from "../../../../assets/images/crm-logo.svg";
import Errorimage from "../../../../assets/images/error icon.svg";
import StudentVerification from "../../../../assets/images/student verification image 1.svg";
import "./StudentVerificationHome.css";
import { AUTH_URLS } from "../../constants/auth-urls";
import { RegisterConstants } from "core/auth/constants/auth-constants";

function StudentVerificationHome() {
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleVerifyDetails = () => {
    if (!emailId || !firstName || !lastName || !dateOfBirth) {
      setErrorMessage(RegisterConstants.STUDENT_VERIFICATION_INVALID);
    } else {
      axios
        .get(`${AUTH_URLS.VERIFICATION_HOME_URL}`, {
          params: {
            email: emailId,
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: dateOfBirth,
          },
        })
        .then((response) => {
          setErrorMessage("");
          if (response.data.message === "success") {
            navigate(`/verification-success`, {
              state: { pdf: response.data.fileData.body },
            });
          } else {
            setErrorMessage(RegisterConstants.STUDENT_VERIFICATION_INVALID);
          }
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message.split(":")[1]);
          setErrorMessage(RegisterConstants.STUDENT_VERIFICATION_INVALID);
        });
    }
  };

  return (
    <div className="d-flex g-0 ">
      <div
        className="d-none d-md-block student-slide auth-img-container  "
        style={{
          width: "40%",
          position: "sticky",
          left: 0,
          top: 0,
          height: "100vh",
        }}
      >
        <img
          src={StudentVerification}
          alt="img"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div
        className=" auth-container auth-text-animation student"
        style={{ width: "100%" }}
      >
        <div className="d-flex w-100 justify-content-between">
          <span className=" student-container-title">
            Student Verification Portal
          </span>
          <img src={crmlogo} alt="logo" style={{ height: "60px" }} />
        </div>
        <div
          className="d-flex row g-0 student-inner-content-container "
          style={{ width: "100%" }}
        >
          <div className="login-head-text-container">
            <span className="auth-title-text student-content-title">
              Please upload your payment information detail here
            </span>
          </div>
          <hr className="student-hr1" />
          <div className="flex-column">
            <div className="student-remarks ">
              <span>
                <label
                  className="auth-label-text mt-2"
                  style={{ marginTop: 0 }}
                >
                  Email ID
                </label>
              </span>
              <input
                style={{ color: "#FFFFFF" }}
                className="student-remarks-input"
                placeholder="Valid Email ID"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
              />
            </div>
            <div className="d-flex student-input-top  col-lg-6">
              <div className="student-document ">
                <span>
                  <label className="auth-label-text mt-2">First Name</label>
                </span>
                <input
                  style={{ color: "#FFFFFF" }}
                  className="student-document-input"
                  placeholder="Your first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="student-document-date">
                <span className="password-text-container">
                  <label className="auth-label-text mt-2">Last Name</label>
                </span>
                <input
                  style={{ color: "#FFFFFF" }}
                  className="student-document-date-input"
                  placeholder="Your last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="student-remarks ">
              <span>
                <label className="auth-label-text mt-2">Date of Birth</label>
              </span>
              <input
                style={{ color: "#FFFFFF" }}
                type="date"
                className="student-remarks-input"
                placeholder="Select a date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>
            <div
              className="student-payment-btn col-10"
              style={{ position: "relative" }}
            >
              <button
                className="student-bttn"
                onClick={handleVerifyDetails}
                style={{ marginTop: "20px" }}
              >
                Verify Student Details
              </button>

              {errorMessage && (
                <div className=" student-error-message-container">
                  {/* <hr className="student-error-hr" />  */}
                  <img src={Errorimage} alt="logo" className="error-icon" />
                  <span className="student-error-text">{errorMessage}</span>
                  <div className="student-error-rect">
                    <p className="student-error-p-tag">
                      Please contact{" "}
                      <span>
                        <a
                          href="mailto: info.beta@gmail.com"
                          className="student-info-beta"
                        >
                          info.beta@gmail.com
                        </a>
                      </span>{" "}
                      if you have any questions.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="policy-text-container mt-5 mt-md-3 ps-1">
          <span>
            <a
              target="_blank"
              style={{ textDecoration: "underline" }}
              href="https://mybeta.ca/privacy-policy/"
              className="auth-label-text mt-2"
            >
              Privacy Policy
            </a>
          </span>
          <a
            target="_blank"
            style={{ textDecoration: "underline" }}
            href="https://betaerp-site-docs.s3.us-east-2.amazonaws.com/TERMS+AND+CONDITIONS.pdf"
            className="auth-label-text mt-2"
          >
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>
  );
}

export default StudentVerificationHome;
