import ViewIcon from "assets/image-components/ViewIcon";
import profile from "assets/images/person.svg";
import "./StudentDashboard.css";
import ReactApexChart from "react-apexcharts";
import { useContext, useEffect, useState } from "react";
import { axiosService } from "services/axios.service";
import { DASHBOARD_URLS } from "modules/dashboard-management/constants/dashboard_urls";
import sessionService from "services/sessionstorage.service";
import { useLocation, useNavigate } from "react-router-dom";
import { GrNext, GrPrevious } from "react-icons/gr";
import SmallCircularProgress from "./SmallCircularProgress";
import InfoIcon from "assets/image-components/InfoIcon";
import Downarrow from "assets/image-components/Downarrow";
import StuVector from "assets/image-components/StuVector";
import { ThemeContext } from "../../../../ThemeContext";
import Exams from "assets/images/Exams";

function StudentDashboard() {
  const userData = sessionService.getUserData("userData");
  const location = useLocation();
  const [dashboardData, setDashboardData] = useState(null);
  const { theme, toggleTheme } = useContext(ThemeContext);
  useEffect(() => {
    axiosService
      .get(`${DASHBOARD_URLS.GET_DASHBOARD_STUDENT_BY_ID}${userData?.userId}`)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data.data;
          setDashboardData(responseData);
        }
      });
  }, [location.pathname]);

  const [currentExamIndex, setCurrentExamIndex] = useState(0);

  const allExams = dashboardData?.upcomingExams
    ? Object.keys(dashboardData?.upcomingExams).flatMap((date) =>
        dashboardData?.upcomingExams[date].map((exam) => {
          const formattedDate = new Date(exam.scheduledDate).toLocaleDateString(
            "en-US",
            { month: "short", day: "numeric" }
          );
          const formattedStartTime = new Date(
            `1970-01-01T${exam.scheduledTime}`
          ).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          const formattedEndTime = new Date(
            `1970-01-01T${exam.endTime}`
          ).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          return {
            moduleName: exam?.module?.module?.moduleName,
            formattedDate,
            formattedStartTime,
            formattedEndTime,
          };
        })
      )
    : null;

  const examsToShow = allExams
    ? allExams?.slice(currentExamIndex, currentExamIndex + 2)
    : null;

  const formatAccessTime = (timeString) => {
    const parts = timeString.match(/(\d+)([a-zA-Z]+)/g);

    return parts.map((part, index) => {
      const number = part.match(/\d+/)[0];
      const unit = part.match(/[a-zA-Z]+/)[0];
      return (
        <span key={index}>
          <span>{number}</span>
          <span style={{ color: "grey", fontSize: "18px", fontWeight: "500" }}>
            {unit}
          </span>{" "}
        </span>
      );
    });
  };

  const handleNextExam = () => {
    if (currentExamIndex + 2 < allExams.length) {
      setCurrentExamIndex(currentExamIndex + 2);
    }
  };

  const handlePreviousExam = () => {
    if (currentExamIndex > 0) {
      setCurrentExamIndex(currentExamIndex - 2);
    }
  };
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };
  const [currentDate, setCurrentDate] = useState(new Date());
  const currentDateForBtn = new Date();

  const handlePreviousDate = () => {
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    setCurrentDate(previousDate);
  };

  const handleNextDate = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    setCurrentDate(nextDate);
  };
  const formatedDate = (dateString) => {
    const date = new Date(dateString);

    const options = { year: "numeric", month: "short", day: "numeric" };

    return date.toLocaleDateString("en-GB", options);
  };

  function formathyphenDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const formattedDate = formathyphenDate(currentDate);
  const classes =
    dashboardData?.myClasses && dashboardData?.myClasses[formattedDate];

  const MonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const overallAttendancePercent = MonthNames.map((month) => {
    const totalCount =
      (dashboardData?.yearwiseAttendanceCount &&
        dashboardData?.yearwiseAttendanceCount[month]?.totalCount) ||
      0;
    const studentCount =
      (dashboardData?.yearwiseAttendanceCount &&
        dashboardData?.yearwiseAttendanceCount[month]?.studentCount) ||
      0;

    if (totalCount === 0) return 0;

    return (studentCount / totalCount) * 100;
  });
  const lineSeries = [
    {
      name: "Overall Percentage",
      data: overallAttendancePercent,
    },
  ];
  const lineOption = {
    chart: {
      width: "100%",
      type: "area",
      height: 350,
      redrawOnWindowResize: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#FFA500"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0,
        opacityTo: 0,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          colors: theme === "light" ? "#000000" : "#ffffff",
          fontFamily: '"Poppins", sans-serif',
          fontSize: "10px",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 2,
      labels: {
        style: {
          colors: theme === "light" ? "#000000" : "#ffffff",
        },
        formatter: function (value) {
          return Math.round(value);
        },
      },
    },
    grid: {
      borderColor: "#666",
      show: false,
      padding: {
        right: 10,
        left: 10,
      },
    },
    tooltip: {
      theme: "dark",
      style: {
        fontSize: "12px",
        colors: ["#000000"],
      },
      marker: {
        show: false,
      },
    },
  };
  const [currentPageMessage, setCurrentPageMessage] = useState(0);
  const [currentPageNotice, setCurrentPageNotice] = useState(0);

  const totalPagesMessage =
    dashboardData?.chatMessage &&
    Math.ceil(Math.max(dashboardData?.chatMessage.length / 2));

  const totalPagesNotice =
    dashboardData?.circularNotice &&
    Math.ceil(Math.max(dashboardData?.circularNotice.length / 2));

  const startIndexChat = currentPageMessage * 2;
  const startIndexCircular = currentPageNotice * 2;

  const paginatedChatMessages = dashboardData?.chatMessage?.slice(
    startIndexChat,
    startIndexChat + 2
  );
  const paginatedCircularNotices = dashboardData?.circularNotice?.slice(
    startIndexCircular,
    startIndexCircular + 2
  );

  const handleNextMessage = () => {
    if (currentPageMessage < totalPagesMessage - 1) {
      setCurrentPageMessage(currentPageMessage + 1);
    }
  };

  const handlePreviousMessage = () => {
    if (currentPageMessage > 0) {
      setCurrentPageMessage(currentPageMessage - 1);
    }
  };

  const handleNextNotice = () => {
    if (currentPageNotice < totalPagesNotice - 1) {
      setCurrentPageNotice(currentPageNotice + 1);
    }
  };

  const handlePreviousNotice = () => {
    if (currentPageNotice > 0) {
      setCurrentPageNotice(currentPageNotice - 1);
    }
  };
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;
  const totalPages =
    dashboardData?.studentModules &&
    Math.ceil(dashboardData?.studentModules.length / itemsPerPage);

  const displayedModules = dashboardData?.studentModules?.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );
  const handleNext = () => {
    if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };
  const navigate = useNavigate();
  const navigateToViewCircular = (circularId) => {
    navigate(`/communication-management/view-circular`, {
      state: { circularId },
    });
  };
  const navigateToExam = (examId) => {
    navigate(`/exam-marksheets/my-exams`, {
      state: { examId },
    });
  };

  let recent = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className="dashboard-main-container">
      <div className="student-dashboard-header-container flex-row gap-2">
        <div className="d-flex align-items-center gap-3">
          <span className="communication-management-title-text">
            My Dashboard
          </span>
        </div>
        <div className="d-none d-lg-flex">
          <button className="communication-save-head-button">
            Add calendar request
          </button>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div class="d-flex row dashboard-sub-container">
          <div className="col-xl-8 col-12 stud-sub-container-1a">
            <div
              className="col-12 col-md-12 "
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <div className="student-dashboard-count-con">
                <div
                  className="dashboard-single-box"
                  style={{ height: "96px" }}
                >
                  <span className="dashboard-box-title-con">
                    <span className="dashboard-count-view-title">
                      Avg. Access Time
                    </span>
                  </span>
                  <span className="stu-dashboard-count-items">
                    <span>
                      {dashboardData?.averageAccessTime &&
                        formatAccessTime(dashboardData.averageAccessTime)}
                    </span>
                  </span>
                </div>
                <div
                  className="dashboard-single-box "
                  style={{ height: "96px" }}
                >
                  <span className="dashboard-box-title-con">
                    <span className="dashboard-count-view-title">
                      Avg. Learning Time
                    </span>
                  </span>
                  <span className="stu-dashboard-count-items">
                    <span>
                      {dashboardData?.averageLearningTime &&
                        formatAccessTime(dashboardData?.averageLearningTime)}
                    </span>
                  </span>
                </div>
              </div>

              <div className="stu-dashboard-single-box ">
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    minHeight: "43px",
                    borderBottom:
                      theme === "light"
                        ? "1px solid #2c2c2c"
                        : "1px solid #525356",
                    justifyContent: "center",
                    padding: "0px 10px",
                  }}
                >
                  <span
                    className="dashboard-box-title-con"
                    style={{ alignItems: "center" }}
                  >
                    <span className="dashboard-count-view-title">
                      Recent Learning activity
                    </span>
                    <span style={{ display: "flex", gap: "5px" }}>
                      <InfoIcon />
                      <ViewIcon />
                    </span>
                  </span>
                </span>
                <div className="Recent-learning-activity-card-container">
                  {dashboardData?.activity?.moduleName &&
                  dashboardData?.activity?.moduleName.length > 0 ? (
                    dashboardData?.activity?.moduleName?.map((a, index) => (
                      <div
                        className="Recent-learning-activity-card"
                        style={{ height: "64px" }}
                      >
                        <div className="Recent-learning-activity-card-left">
                          <div className="Recent-learning-activity-card-left-header">
                            {a?.moduleName}
                          </div>
                          <div className="Recent-learning-activity-card-left-current-status">
                            Current : {a?.current}
                          </div>
                        </div>
                        <div className="Recent-learning-activity-card-Right">
                          <Exams
                            color={theme === "light" ? "#000000" : "#ffffff"}
                            style={{ height: "41px" }}
                          />
                          <div className="Recent-learning-activity-card-Right-progress-con">
                            <SmallCircularProgress
                              percentage={a?.complete}
                              color={theme === "light" ? "#000000" : "#ffffff"}
                            />
                            <span className="Recent-learning-activity-progress-below-text">
                              {a?.completeChapters}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-text dashboard-upcoming-module-text">
                      No recent learning activity to display
                    </div>
                  )}
                </div>
              </div>
              <div className="student-dashboard-faculty-con">
                <div
                  className="student-dashboard-messages-container"
                  style={{ overflow: "hidden" }}
                >
                  <span className="dashboard-box-title-con">
                    <span className="dashboard-count-view-title">
                      New Messages / Notifications
                    </span>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                        }}
                      >
                        <span>
                          <GrPrevious
                            size={12}
                            onClick={handlePreviousMessage}
                            style={{
                              opacity: currentPageMessage === 0 ? 0.5 : 1,
                              cursor:
                                currentPageMessage === 0
                                  ? "context-menu"
                                  : "pointer",
                            }}
                          />
                        </span>
                        <span>
                          <GrNext
                            size={12}
                            onClick={handleNextMessage}
                            style={{
                              opacity:
                                currentPageMessage === totalPagesMessage - 1
                                  ? 0.5
                                  : 1,
                              cursor:
                                currentPageMessage === totalPagesMessage - 1
                                  ? "context-menu"
                                  : "pointer",
                            }}
                          />
                        </span>
                        <span>
                          <ViewIcon />
                        </span>
                      </span>
                    </span>
                  </span>

                  <div>
                    {paginatedChatMessages &&
                    paginatedChatMessages.length > 0 ? (
                      paginatedChatMessages.map((chat, index) => (
                        <span
                          key={index}
                          className="chat-msg-con"
                          style={{
                            borderBottom:
                              index % 2 === 0
                                ? theme === "light"
                                  ? "1px solid #2c2c2c"
                                  : "1px solid rgba(255, 255, 255, 0.22)"
                                : "none",
                          }}
                        >
                          <div
                            className="dashboard-img-main"
                            style={{ height: "66px" }}
                          >
                            <div className="student-dashboard-fac-name-con">
                              <img
                                src={
                                  chat?.profilePicture
                                    ? chat?.profilePicture
                                    : profile
                                }
                                alt="img"
                                style={{ width: "50px", height: "50px" }}
                              />
                              <span className="student-dashboard-fac-name">
                                <span className="dashboard-sender">
                                  <span className="dashboard-sender-name">
                                    {chat.senderName}
                                  </span>
                                  <span className="stud-message-time">
                                    {chat.latestMessageTime}
                                    <div
                                      style={{
                                        height: "10px",
                                        width: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: "#047BC1",
                                        display: "inline-block",
                                        marginLeft: "5px",
                                      }}
                                    ></div>
                                  </span>
                                </span>
                                <span className="dashboard-sender-message">
                                  {chat.latestMessage}
                                </span>
                              </span>
                            </div>
                          </div>
                        </span>
                      ))
                    ) : (
                      <div className="no-data-text dashboard-upcoming-module-text">
                        No Messages / Notifications to display
                      </div>
                    )}

                    {/* {(!paginatedChatMessages ||
                      paginatedChatMessages.length === 0) &&
                      (!paginatedCircularNotices ||
                        paginatedCircularNotices.length === 0) && (
                        <span className="no-data-text dashboard-upcoming-module-text">
                          No messages or circulars to display
                        </span>
                      )} */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-md-12 "
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <div className="student-dashboard-count-con">
                <div
                  className="dashboard-single-box "
                  style={{ height: "96px" }}
                >
                  <span className="dashboard-box-title-con">
                    <span className="dashboard-count-view-title">
                      Progress Chart
                    </span>
                  </span>

                  <span className="dashboard-graph-count">
                    <svg
                      height="10"
                      viewBox="0 0 60 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 7.12756L6.90769 8.87979C9.21204 9.56326 11.6492 8.7015 13.65 7.36947C15.1524 6.36916 17.0019 5.67296 18.6292 6.6107C22.0881 8.60398 22.2897 0.480277 26.328 1.07392C28.3364 1.36917 29.6916 0.843575 31.5 4C32.9359 6.50633 34.9916 5.65106 37 5.65106L40.9341 5.41631C41.6057 5.37623 42.2659 5.22346 42.8869 4.96439L43.3143 4.78608C43.7678 4.59688 44.184 4.32834 44.5432 3.99308V3.99308C46.2832 2.36948 49.0311 2.54601 50.5489 4.3789L51.2015 5.16691C51.8608 5.96301 52.7465 6.53995 53.7411 6.82127L59 8.3087"
                        stroke="#F7931D"
                        stroke-width="1.5"
                      />
                    </svg>
                    <text style={{ fontSize: "24px" }}>
                      {Math.round(parseFloat(dashboardData?.progressChart))}%
                    </text>
                  </span>
                </div>
                <div
                  className="dashboard-single-box "
                  style={{ height: "96px" }}
                >
                  <span className="dashboard-box-title-con">
                    <span className="dashboard-count-view-title">
                      Fees Paid/ <span style={{ fontSize: "12px" }}>Total</span>
                    </span>
                  </span>
                  <span className="stu-dashboard-count-items">
                    <text>
                      ${dashboardData?.paidFees}/{" "}
                      <span style={{ fontSize: "14px" }}>
                        {dashboardData?.totalFees}
                      </span>
                    </text>
                  </span>
                </div>
              </div>
              <div className="student-dashboard-faculty-con">
                <div
                  className="student-dashboard-messages-container"
                  style={{ height: "400px" }}
                >
                  <span className="dashboard-box-title-con">
                    <span
                      className="dashboard-count-view-title"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",

                        maxWidth: "300px",
                      }}
                    >
                      Upcoming Exams / Marks obtained
                    </span>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                        }}
                      >
                        <span
                          onClick={handlePreviousExam}
                          disabled={currentExamIndex === 0}
                        >
                          <GrPrevious
                            size={12}
                            style={{
                              color:
                                currentExamIndex === 0 ? "#8c8c8c" : "#ffffff",
                              cursor:
                                currentExamIndex === 0
                                  ? "context-menu"
                                  : "pointer",
                            }}
                          />
                        </span>
                        <span
                          onClick={handleNextExam}
                          disabled={
                            allExams
                              ? currentExamIndex + 2 >= allExams?.length
                              : null
                          }
                        >
                          <GrNext
                            size={12}
                            style={{
                              color: allExams
                                ? currentExamIndex + 2 >= allExams?.length
                                  ? "#8c8c8c"
                                  : "#ffffff"
                                : null,
                              cursor:
                                currentExamIndex + 2 >= allExams?.length
                                  ? "context-menu"
                                  : "pointer",
                            }}
                          />
                        </span>

                        <span>
                          <ViewIcon />
                        </span>
                      </span>
                    </span>
                  </span>

                  {examsToShow?.length > 0 ||
                  (dashboardData?.currentGrade &&
                    dashboardData?.currentGrade.length > 0) ? (
                    <>
                      <div className="stud-dashboard-exam-card-container">
                        {examsToShow &&
                          examsToShow.map((item, index) => (
                            <div className="stud-dashboard-exam-card">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  fontSize: "14px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "50%",
                                    backgroundColor: "#59C37B",
                                  }}
                                ></div>
                                <span>{item?.moduleName}</span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  fontSize: "12px",
                                }}
                              >
                                <span>
                                  {item?.formattedDate} -{" "}
                                  {item?.formattedStartTime} -{" "}
                                  {item?.formattedEndTime}
                                </span>
                                <span
                                  className="stud-dashboard-exam-take-exam"
                                  onClick={() => {
                                    navigateToExam();
                                  }}
                                >
                                  Take Exam
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>

                      <div className="current-grade-container">
                        {dashboardData?.currentGrade &&
                          dashboardData?.currentGrade.map(
                            (classItem, index) => (
                              <div key={index} className="class-schedule">
                                <span className="class-title">
                                  {classItem.module?.module?.moduleCode}{" "}
                                  {classItem?.module?.module?.moduleName}
                                </span>
                                <div className="class-time-icon">
                                  <span className="class-percentage">
                                    {Math.round(classItem.markPercentage)}%
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </>
                  ) : (
                    <div className="no-data-text dashboard-upcoming-module-text">
                      No Upcoming Exams / Marks obtained to display
                    </div>
                  )}
                </div>
              </div>
              <div
                className="student-dashboard-faculty-con"
                style={{ overflow: "hidden" }}
              >
                <div
                  className="student-dashboard-upcoming-exams-container "
                  style={{ height: "220px" }}
                >
                  <span className="dashboard-box-title-con">
                    <span className="dashboard-count-view-title">
                      Circulars / Notices
                    </span>
                    <span>
                      <span
                        style={{
                          display: "flex",
                          gap: "15px",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "row",
                          }}
                        >
                          <span
                            onClick={handlePreviousNotice}
                            disabled={currentExamIndex === 0}
                          >
                            <GrPrevious
                              size={12}
                              onClick={handlePreviousNotice}
                              style={{
                                opacity: currentPageNotice === 0 ? 0.5 : 1,
                                cursor:
                                  currentPageNotice === 0
                                    ? "context-menu"
                                    : "pointer",
                              }}
                            />
                          </span>
                          <span
                            onClick={handleNextNotice}
                            style={{
                              opacity:
                                currentPageNotice === totalPagesNotice - 1
                                  ? 0.5
                                  : 1,
                              cursor:
                                currentPageNotice === totalPagesNotice - 1
                                  ? "context-menu"
                                  : "pointer",
                            }}
                          >
                            <GrNext
                              size={12}
                              style={{
                                color: allExams
                                  ? currentExamIndex + 2 >= allExams.length
                                    ? "#8c8c8c"
                                    : "#ffffff"
                                  : null,
                              }}
                            />
                          </span>
                        </div>
                        <ViewIcon />
                      </span>
                    </span>
                  </span>

                  <div>
                    {paginatedCircularNotices &&
                    paginatedCircularNotices.length > 0 ? (
                      paginatedCircularNotices.map((circular, index) => (
                        <div
                          key={index}
                          className="circular-msg-con"
                          style={{
                            borderBottom:
                              index % 2 === 0
                                ? theme === "light"
                                  ? "1px solid #2c2c2c"
                                  : "1px solid rgba(255, 255, 255, 0.22)"
                                : "none",
                          }}
                        >
                          <div className="dashboard-img-main">
                            <div
                              className="student-dashboard-fac-name-con"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span className="student-dashboard-fac-name">
                                <span className="dashboard-circular-title">
                                  Notice Title - {circular.title}
                                </span>
                              </span>
                              <span className="stuvector">
                                <StuVector />
                              </span>
                            </div>
                          </div>
                          <div className="circular-time-con">
                            <span
                              className="view-circular-text"
                              onClick={() => {
                                navigateToViewCircular(circular.circularId);
                              }}
                            >
                              View Circular
                            </span>
                            <span
                              className="message-time"
                              style={{ textAlign: "right" }}
                            >
                              {formatedDate(circular.updatedOn)}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no-data-text dashboard-upcoming-module-text">
                        No Circulars / Notices to display
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            // className="col-12 col-md-4"
            className="col-xl-4 col-12 stud-sub-container-1b"
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <div className="student-dashboard-faculty-con">
              <div className="student-dashboard-upcoming-exams-container ">
                <span className="dashboard-box-title-con dashboard-bottom-title">
                  <span
                    className="dashboard-count-view-title"
                    style={{ fontWeight: "700", color: "#F69F3A" }}
                  >
                    Current/Upcoming Session
                  </span>
                  <span>
                    <span
                      style={{
                        display: "flex",
                        gap: "15px",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          flexDirection: "row",
                        }}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={
                            currentDate.toString().substring(0, 15) !==
                            currentDateForBtn.toString().substring(0, 15)
                              ? handlePreviousDate
                              : null
                          }
                        >
                          <GrPrevious
                            size={12}
                            style={{
                              color:
                                currentDate.toString().substring(0, 15) ===
                                currentDateForBtn.toString().substring(0, 15)
                                  ? "#8c8c8c"
                                  : "#ffffff",
                            }}
                          />
                        </span>

                        <span
                          onClick={handleNextDate}
                          style={{ cursor: "pointer" }}
                        >
                          <GrNext size={12} />
                        </span>
                      </div>
                      <ViewIcon />
                    </span>
                  </span>
                </span>

                <div className="stu-dashboard-current-session-card-container">
                  {classes && classes.length > 0 ? (
                    classes.map((classItem, index) => (
                      <>
                        {classItem?.isActive === true ? (
                          <div className="stu-dashboard-current-session-card-open">
                            <div className="stu-dashboard-current-session-card-opened">
                              <div className="stu-dashboard-current-session-card-opened-left-container">
                                <span className="stu-dashboard-current-session-card-opened-title">
                                  {classItem?.module?.module?.moduleName}
                                </span>
                                <span className="stu-dashboard-current-session-card-opened-time">
                                  {classItem?.startTime} - {classItem?.endTime}{" "}
                                  <span className="stu-dashboard-current-icon">
                                    Current
                                  </span>
                                </span>
                              </div>
                              <div className="stu-dashboard-current-session-card-opened-right-container">
                                <span className="stu-dashboard-current-session-card-opened-green"></span>
                              </div>
                            </div>
                            <div className="stu-dashboard-current-session-card-open-fac">
                              <div className="stu-dashboard-current-session-card-open-fac-left">
                                <span>
                                  <img
                                    // src={ProfileImg}
                                    src={
                                      classItem?.facultyAssigned?.profileImage
                                        ? classItem?.facultyAssigned
                                            ?.profileImage
                                        : profile
                                    }
                                    alt="img"
                                    style={{ width: "41px", height: "41px" }}
                                  />
                                </span>
                                <div>
                                  <div className="stu-dashboard-current-session-card-open-fac-left-name">
                                    {classItem?.facultyAssigned?.role?.name}
                                  </div>
                                  <div className="stu-dashboard-current-session-card-open-fac-left-position">
                                    {classItem?.facultyAssigned?.studentType}
                                  </div>
                                </div>
                              </div>
                              <div className="stu-dashboard-current-session-card-open-fac-right">
                                <button className="stu-dashboard-current-session-card-open-fac-right-btn">
                                  Join now
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="stu-dashboard-current-session-card-closed">
                            <div className="stu-dashboard-current-session-card-closed-left-container">
                              <span className="stu-dashboard-current-session-card-closed-title">
                                {classItem?.module?.module?.moduleName}
                              </span>
                              <span className="stu-dashboard-current-session-card-closed-time">
                                {classItem?.startTime} - {classItem?.endTime}
                                {currentDate.toString().substring(0, 15) ===
                                currentDateForBtn.toString().substring(0, 15)
                                  ? " - Today"
                                  : ` (${formattedDate})`}
                              </span>
                            </div>
                            <div className="stu-dashboard-current-session-card-closed-right-container">
                              <span className="stu-dashboard-current-session-card-closed-darrow">
                                <Downarrow />
                              </span>

                              <img
                                src={
                                  classItem?.facultyAssigned?.profileImage
                                    ? classItem?.facultyAssigned?.profileImage
                                    : profile
                                }
                                className="stu-dashboard-current-session-card-closed-img"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ))
                  ) : (
                    <div className="no-data-text dashboard-upcoming-module-text">
                      No Current Session to display, Kindly check upcoming
                      sessions
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="student-dashboard-faculty-con medium-container">
              <div
                className="dashboard-single-box "
                style={{ height: "220px" }}
              >
                <span className="dashboard-box-title-con">
                  <span className="dashboard-count-view-title">
                    Monthly Attendance
                  </span>
                  <span>
                    <ViewIcon />
                  </span>
                </span>
                <ReactApexChart
                  options={lineOption}
                  series={lineSeries}
                  type="area"
                  height={170}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDashboard;
