import sort from "assets/images/cm/Cam/sort.png";
import CLOAForDocument from "modules/documents/components/enrollment-letter/cloa";
import EnrollmentView from "modules/documents/components/enrollment-letter/enrollment";
import FLOAForDocument from "modules/documents/components/enrollment-letter/floa";
import DLOADocument from "modules/documents/components/enrollment-letter/dloa";
import FLOAInternationalDocument from "modules/documents/components/enrollment-letter/floaInternational";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { useContext, useEffect, useState } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { IoClose, IoEyeOutline } from "react-icons/io5";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import sessionService from "services/sessionstorage.service";
import "./Documents.css";
import { DOCUMENTS_URL_FOR_STUDENT } from "./constants/student-portal-documents-urls";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import EnrollmentContract from "./EnrollmentContract/EnrollmentContract";
import { DOCUSIGN_URL } from "modules/my-profile/constants/docusign-urls";
import betalogo from "assets/images/icons/beta.svg";
import ActivePolicyContract from "./ActivePolicyContract/ActivePolicyContract";
import { BsDownload } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { BiCheck } from "react-icons/bi";
import { ThemeContext } from "ThemeContext";

const PreStudentDocuments = () => {
  const date = new Date();
  const requestDate = new Date(date).toISOString().split("T")[0];
  const location = useLocation();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [isDloaModalOpen, setDloaModalOpen] = useState(false);
  const [isVerificationModalOpen, setVerificationModalOpen] = useState(false);
  const [isEnrollmentModalOpen, setEnrollmentModalOpen] = useState(false);
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [modalName, setModalName] = useState("");
  const [modalData, setModalData] = useState(null);
  const [comments, setComments] = useState("");
  const [dloaRequestDate, setDloaRequestDate] = useState("");
  const [docSort, setDocSort] = useState(true);
  const [paymentReceiptName, setPaymentReceiptName] = useState("");
  const [paymentReceipt, setPaymentReceipt] = useState("");
  const [docName, setDocName] = useState("");
  const [pdfData, setPdfData] = useState("");
  const [downloadType, setDownloadType] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const userData = sessionService.getUserData();

  const [admissionData, setAdmissionData] = useState();
  const [recipientEmail, setReceipientEmail] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const envelopeId = searchParams.get("envelopeId");
  const studentType = userData?.studentType;
  const [enrollmentPDF, setEnrollmentPDF] = useState(false);
  const [enrollmentPdfData, setEnrollmentPdfData] = useState(false);
  const [activePolicyPDF, setActivePolicyPDF] = useState(false);
  const [activePolicyPdfData, setActivePolicyPdfData] = useState(false);
  let recipientName = admissionData?.personalData?.firstName;
  const [loading, setLoading] = useState(false);
  const preStudentData = sessionService.getPreStudentData();
  const preStudentDocumentId = sessionService.getPreStudentDocumentId();

  useEffect(() => {
    const getAdmissionData = async () => {
      const response = await axiosService.get(
        DOCUMENTS_URL_FOR_STUDENT?.GET_USER_DATA_BY_USER_ID + userData?.userId
      );
      if (response?.status === 200) {
        const responseData = response?.data?.data?.admissionManagement;
        setAdmissionData(responseData);
        setReceipientEmail(responseData?.personalData?.email);
      }
    };

    getAdmissionData();
  }, [location.pathname, envelopeId, preStudentData]);

  useEffect(() => {
    if (envelopeId && !preStudentData && preStudentDocumentId === 3) {
      setVerificationModalOpen(true);
    } else if (envelopeId && preStudentDocumentId === 14) {
      setEnrollmentModalOpen(true);
    } else {
    }
  }, [envelopeId, preStudentDocumentId]);

  useEffect(() => {
    if (envelopeId && preStudentDocumentId && preStudentData) {
      setStatusModalOpen(true);
    } else {
    }
  }, [envelopeId]);

  const handleEnvelopeIdFromRouter = async () => {
    if (envelopeId && preStudentDocumentId === 3) {
      setLoading(true);
      try {
        const response = await axiosService.get(
          `${DOCUSIGN_URL.PRE_DOC_CALLBACK_FUNCTION}?envelopeId=${envelopeId}&recipientEmail=${recipientEmail}&facultyEmail=faculty@gmail.com`
        );
        if (response.status === 200) {
          const fileData = response.data;
          setPaymentReceiptName("active_policy.pdf");
          setPaymentReceipt(fileData?.url);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching file:", error);
      }
    }
  };
  const handleEnvelopeIdFromRouterEnroll = async () => {
    if (envelopeId && preStudentDocumentId === 14 && recipientEmail) {
      setLoading(true);
      try {
        const response = await axiosService.get(
          `${DOCUSIGN_URL.PRE_DOC_CALLBACK_FUNCTION_ENROLL}?envelopeId=${envelopeId}&recipientEmail=${admissionData?.personalData?.email}&facultyEmail=faculty@gmail.com`
        );
        if (response.status === 200) {
          const fileData = response.data;
          setPaymentReceiptName("enrollment_contract.pdf");
          setPaymentReceipt(fileData?.url);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching file:", error);
      }
    }
  };
  useEffect(() => {
    handleEnvelopeIdFromRouter();
    handleEnvelopeIdFromRouterEnroll();
  }, [envelopeId, preStudentData, recipientEmail]);

  useEffect(() => {
    const sendAgreementEnvelope = async () => {
      if (activePolicyPdfData) {
        try {
          const response = await axiosService.postFile2(
            `${DOCUSIGN_URL.SEND_AGREEMENT_ENVELOPE}`,
            recipientName,
            recipientEmail,
            activePolicyPdfData
          );

          if (response.status === 200) {
            sessionService.setPreStudentDocumentId(3);
            window.open(response.data, "_self");
          }
        } catch (error) {
          console.error(error);
          toast.error(
            "An error occurred while sending the agreement envelope."
          );
        }
      }
    };

    sendAgreementEnvelope();
  }, [activePolicyPdfData, recipientName, recipientEmail]);

  useEffect(() => {
    const sendAgreementEnvelope = async () => {
      if (enrollmentPdfData) {
        try {
          const response = await axiosService.postFile2(
            `${DOCUSIGN_URL.SEND_AGREEMENT_ENVELOPE}`,
            recipientName,
            recipientEmail,
            enrollmentPdfData
          );
          if (response.status === 200) {
            sessionService.setPreStudentDocumentId(14);
            window.open(response.data, "_self");
          }
        } catch (error) {
          console.error(error);
          toast.error(
            "An error occurred while sending the agreement envelope."
          );
        }
      }
    };

    sendAgreementEnvelope();
  }, [enrollmentPdfData]);

  const handleSort = () => {
    var sortingData;
    if (docSort === false) {
      sortingData = rowData.sort((a, b) => {
        let ta = a.docName.toLowerCase();
        let tb = b.docName.toLowerCase();
        if (ta < tb) {
          return -1;
        }
        if (ta > tb) {
          return 1;
        }
        return 0;
      });
      setRowData([...sortingData]);
      setDocSort(!docSort);
    } else if (docSort === true) {
      sortingData = rowData.sort((a, b) => {
        return a.documentId - b.documentId;
      });
      setRowData([...sortingData]);
      setDocSort(!docSort);
    }
  };

  const getDocumentById = (id) => {
    axiosService
      .get(`${DOCUMENTS_URL_FOR_STUDENT.GET_DOCUMENTS_BY_ID}${id}`)
      .then((response) => {
        const data = response?.data?.data;
        setModalData(data);
      });
  };

  useEffect(() => {
    if (preStudentDocumentId && preStudentData) {
      getDocumentById(preStudentData);
    }
  }, [preStudentDocumentId, preStudentData]);

  const handleEyeClick = (docName, type) => {
    axiosService
      .get(`${DOCUMENT_URLS.GET_ADMISSION_BY_ID}${admissionData?.admissionId}`)
      .then((response) => {
        const data = response?.data?.data;
        setDownloadType(type);
        setDocName(docName);
        setPdfData(data);
      })
      .catch((error) => {
        toast.error("Failed to load document. Please try again later.");
      });
  };

  const handleSubmitRequest = async () => {
    setLoading(true);
    try {
      const requestData = {
        document: {
          documentId: 3,
        },
        requestDate: requestDate,
        paymentReceipt: paymentReceipt,
        paymentReceiptName: paymentReceiptName,
        requestorComment: comments,
      };

      const response = await axiosService.post(
        `${DOCUMENTS_URL_FOR_STUDENT.REQUEST_VERIFICATION_URL}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Requested document submitted successfully");
        onClose();
        setLoading(false);
        getRowData();
        // setRefresh(!refresh);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleSubmitRequestDLOA = async () => {
    setLoading(true);
    try {
      const requestData = {
        document: {
          documentId: 15,
        },
        requestDate: requestDate,
        paymentReceipt: paymentReceipt,
        paymentReceiptName: paymentReceiptName,
        requestorComment: comments,
        studentDate: dloaRequestDate,
      };

      const response = await axiosService.post(
        `${DOCUMENTS_URL_FOR_STUDENT.REQUEST_VERIFICATION_URL}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Requested document submitted successfully");
        onClose();
        setLoading(false);
        getRowData();
        // setRefresh(!refresh);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleSubmitEnrollmentRequest = async () => {
    setLoading(true);
    try {
      const requestData = {
        document: {
          documentId: 14,
        },
        requestDate: requestDate,
        paymentReceipt: paymentReceipt,
        paymentReceiptName: paymentReceiptName,
        requestorComment: comments,
      };

      const response = await axiosService.post(
        `${DOCUMENTS_URL_FOR_STUDENT.REQUEST_VERIFICATION_URL}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Requested document submitted successfully");
        onClose();
        setLoading(false);
        // setRefresh(!refresh);
        getRowData();
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const user = sessionService.getUserData("userData");

  const addReminder = async (docId) => {
    setLoading(true);
    try {
      const requestData = {
        document: {
          documentId: docId,
        },
        docRequestId: modalData?.docRequestId,
        requestDate: modalData?.requestDate,
        requestorComment: modalData?.requestorComment,
        paymentReceipt: modalData?.paymentReceipt,
        paymentReceiptName: modalData?.paymentReceiptName,
        user: {
          userId: user?.userId,
        },
        studentDate: modalData?.studentDate,
        facultyStatus: modalData?.facultyStatus,
        isFacultyApproved: modalData?.isFacultyApproved,
      };

      const response = await axiosService.put(
        `${DOCUMENTS_URL_FOR_STUDENT.ADD_REMINDER}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Reminder added successfully");
        setRefresh(!refresh);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleUploadReceipt = async (docId) => {
    setLoading(true);
    try {
      const requestData = {
        document: {
          documentId: docId,
        },
        docRequestId: modalData?.docRequestId,
        requestDate: requestDate,
        paymentReceipt: paymentReceipt,
        paymentReceiptName: paymentReceiptName,
        requestorComment: comments,
        user: {
          userId: user?.userId,
        },
        studentDate: dloaRequestDate,
      };

      const response = await axiosService.put(
        `${DOCUMENTS_URL_FOR_STUDENT.REQUEST_VERIFICATION_URL}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Document updated successfully");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const onClose = async () => {
    await navigate("/student-portal/pre-student-view-documents");
    await sessionService.setPreStudentData(null);
    await sessionService.setPreStudentDocumentId(null);
    setVerificationModalOpen(false);
    setDloaModalOpen(false);
    setEnrollmentModalOpen(false);
    setStatusModalOpen(false);
    setPaymentReceipt("");
    setPaymentReceiptName("");
    setModalData("");
    setComments("");
    setModalName("");
  };

  const getRowData = () => {
    axiosService
      .get(`${DOCUMENTS_URL_FOR_STUDENT.GET_DOCUMENTS_URL}`)
      .then((response) => {
        const data = response?.data?.data;

        if (data.length >= 4) {
          // Define the required order of documents
          const requiredDocs = [
            "Conditional Letter of Acceptance",
            "Letter of Acceptance",
            "DLOA",
            "Active Policy",
            "Enrollment Contract",
          ];
          const modifiedData = requiredDocs.map((docName, index) => {
            const doc = data.find((d) => d.docName === docName);
            return doc
              ? {
                  ...doc,
                  documentStatus: index < 2 ? "APPROVED" : "Not Started",
                  index: index + 1,
                }
              : {
                  docName: docName,
                  documentStatus: "Not Started",
                  index: index + 1,
                };
          });

          setRowData(modifiedData);
        } else {
          console.error("Insufficient documents to display.");
        }
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });
  };

  useEffect(() => {
    getRowData();
  }, [isVerificationModalOpen, isEnrollmentModalOpen, isStatusModalOpen]);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const timeString = new Intl.DateTimeFormat("en-US", options).format(date);

    const formattedDate = `${date.getFullYear()}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`;

    return `${timeString} on ${formattedDate}`;
  }

  const handleActiveClick = (docId, type) => {
    axiosService
      .get(`${DOCUMENT_URLS.GET_DOCUMENT_BY_ID}${docId}`)
      .then((response) => {
        const paymentReceiptUrl = response?.data?.data?.paymentReceipt;

        if (paymentReceiptUrl) {
          setLoading(true);
          fetch(paymentReceiptUrl)
            .then((response) => response.blob())
            .then((blob) => {
              if (type === "view") {
                const url = window.URL.createObjectURL(blob);
                window.open(url, "_blank");
                setLoading(false);
              } else if (type === "download") {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "ActivePolicy.pdf");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoading(false);
              }
            })
            .catch((error) => {
              console.error("Error downloading file:", error);
              setLoading(false);
            });
        } else {
          console.error("Payment receipt URL not found in response");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching document:", error);
        setLoading(false);
      });
  };
  const handleEnrollClick = (docId, type) => {
    axiosService
      .get(`${DOCUMENT_URLS.GET_DOCUMENT_BY_ID}${docId}`)
      .then((response) => {
        const paymentReceiptUrl = response?.data?.data?.paymentReceipt;

        if (paymentReceiptUrl) {
          setLoading(true);
          fetch(paymentReceiptUrl)
            .then((response) => response.blob())
            .then((blob) => {
              if (type === "view") {
                const url = window.URL.createObjectURL(blob);
                window.open(url, "_blank");
                setLoading(false);
              } else if (type === "download") {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "EnrollmentLetter.pdf");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoading(false);
              }
            })
            .catch((error) => {
              console.error("Error downloading file:", error);
              setLoading(false);
            });
        } else {
          console.error("Payment receipt URL not found in response");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching document:", error);
        setLoading(false);
      });
  };
  return (
    <div className="view-leads-table-container">
      <div className="student-ps-top sticky-header">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">
            My Pre-Student Documents{" "}
          </span>
        </div>
      </div>
      <div
        className="student-portal-inner-main-container "
        style={{ width: "100%", overflow: "unset", height: "auto" }}
      >
        <div
          className="custom-scrollbar"
          style={{ overflowX: "auto", overflowY: "auto" }}
        >
          <table class="Documents-table">
            <thead>
              <tr>
                <th
                  className="pre-student-doc-table-head"
                  style={{ position: "relative", paddingRight: "25px" }}
                  onClick={handleSort}
                >
                  Document Name
                  <img src={sort} id="Documents-sorting-Arrow" />
                </th>
                <th className="pre-student-doc-table-head">Status</th>
                <th className="pre-student-doc-table-head">View</th>
                {!admissionData?.programDetails[0]?.applicationNo && (
                  <th className="pre-student-doc-table-head">Download</th>
                )}
              </tr>
            </thead>
            <tbody>
              {rowData.map((a, i) =>
                (studentType === "OSAP" &&
                  (a.docName === "Enrollment Contract" ||
                    a.docName === "Active Policy" ||
                    a.docName === "Letter of Acceptance")) ||
                studentType !== "OSAP" ? (
                  <tr
                    key={i}
                    className={
                      modalName === a.docName ? "Documents-table-activerow" : ""
                    }
                  >
                    {(a.docName !== "Active Policy" ||
                      studentType === "OSAP") && <td>{a.docName}</td>}
                    {(a.docName !== "Active Policy" ||
                      studentType === "OSAP") && (
                      <td
                        style={
                          a.isActive === false
                            ? { color: "#7A7A7A", display: "flex" }
                            : { display: "flex" }
                        }
                      >
                        {(a.docName === "Enrollment Contract" ||
                          a.docName === "Active Policy" ||
                          a.docName === "DLOA") &&
                          (a.documentRequest ? (
                            a.documentRequest.overallStatus === "OPEN" ||
                            a.documentRequest.overallStatus === "PENDING" ? (
                              <div
                                style={{
                                  width: "6px",
                                  height: "19px",
                                  borderRadius: "2px",
                                  backgroundColor: "#F69F3A",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ) : a.documentRequest.overallStatus ===
                              "REJECTED" ? (
                              <div
                                style={{
                                  width: "6px",
                                  height: "19px",
                                  borderRadius: "2px",
                                  backgroundColor: "#F6413A",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ) : (a.isActive === true &&
                                a.needVerification === false) ||
                              a.documentRequest.overallStatus === "APPROVED" ? (
                              <div
                                style={{
                                  width: "6px",
                                  height: "19px",
                                  borderRadius: "2px",
                                  backgroundColor: "#21BC53",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ) : (
                              <div
                                style={{
                                  width: "6px",
                                  height: "19px",
                                  borderRadius: "2px",
                                  backgroundColor: "#8C8C8C",
                                  marginRight: "5px",
                                }}
                              ></div>
                            )
                          ) : (
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor: "#8C8C8C",
                                marginRight: "5px",
                              }}
                            ></div>
                          ))}

                        {(a.docName === "Enrollment Contract" ||
                          a.docName === "Active Policy" ||
                          a.docName === "DLOA") &&
                          (a.isActive === false
                            ? "Inactive"
                            : a.documentRequest?.overallStatus === "APPROVED"
                            ? "Complete"
                            : a.documentRequest?.overallStatus === "OPEN"
                            ? "Open"
                            : a.documentRequest?.overallStatus === "PENDING"
                            ? "Pending"
                            : a.documentRequest?.overallStatus === "REJECTED"
                            ? "Rejected"
                            : a.isActive === true &&
                              a.needVerification === false
                            ? "Complete"
                            : "Not Started")}

                        {a.docName === "Conditional Letter of Acceptance" && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor:
                                  admissionData?.cloaStudentPayments.length ===
                                    0 || !admissionData
                                    ? "#8C8C8C"
                                    : "#21BC53",
                                marginRight: "5px",
                              }}
                            ></div>
                            <span>
                              {admissionData?.cloaStudentPayments.length ===
                                0 || !admissionData
                                ? "Not Started"
                                : "Complete"}
                            </span>
                          </div>
                        )}

                        {a.docName === "Letter of Acceptance" && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor:
                                  admissionData?.studentPayments.length === 0
                                    ? "#8C8C8C"
                                    : "#21BC53",
                                marginRight: "5px",
                              }}
                            ></div>
                            <span>
                              {admissionData?.studentPayments.length === 0
                                ? "Not Started"
                                : "Complete"}
                            </span>
                          </div>
                        )}
                      </td>
                    )}
                    {(a.docName !== "Active Policy" ||
                      studentType === "OSAP") && (
                      <td className="text-center p-0">
                        {modalName === a.docName ? (
                          <IoEyeOutline size="16px" color="#F69F3A" />
                        ) : a.isActive === false ? (
                          <IoEyeOutline size="16px" color="#4E4E4E" />
                        ) : a.docName === "Active Policy" ? (
                          <IoEyeOutline
                            onClick={() =>
                              handleActiveClick(
                                a?.documentRequest?.docRequestId,
                                "view"
                              )
                            }
                            size="16px"
                            color={theme === "light" ? "#000000" : "#FFFFFF"}
                          />
                        ) : a.docName === "DLOA" ? (
                          <IoEyeOutline
                            onClick={() => handleEyeClick(a?.docName, "view")}
                            size="16px"
                            color={theme === "light" ? "#000000" : "#FFFFFF"}
                          />
                        ) : a.docName === "Enrollment Contract" ? (
                          <IoEyeOutline
                            onClick={() =>
                              handleEnrollClick(
                                a?.documentRequest?.docRequestId,
                                "view"
                              )
                            }
                            size="16px"
                            color={theme === "light" ? "#000000" : "#FFFFFF"}
                          />
                        ) : (
                          <IoEyeOutline
                            onClick={() => {
                              if (
                                a.docName ===
                                  "Conditional Letter of Acceptance" &&
                                admissionData?.cloaStudentPayments?.length >
                                  0 &&
                                admissionData
                              ) {
                                handleEyeClick(a.docName, "view");
                              } else if (
                                a.docName === "Letter of Acceptance" &&
                                admissionData?.studentPayments?.length > 0 &&
                                admissionData
                              ) {
                                handleEyeClick(a.docName, "view");
                              }
                            }}
                            size={"16px"}
                            color={theme === "light" ? "#000000" : "#FFFFFF"}
                            className={`${
                              (a.docName ===
                                "Conditional Letter of Acceptance" &&
                                admissionData?.cloaStudentPayments?.length >
                                  0 &&
                                admissionData) ||
                              (a.docName === "Letter of Acceptance" &&
                                admissionData?.studentPayments?.length > 0 &&
                                admissionData)
                                ? ""
                                : "opacity-50"
                            }`}
                          />
                        )}
                      </td>
                    )}
                    {(a.docName !== "Active Policy" ||
                      studentType === "OSAP") && (
                      <td>
                        {(a.documentRequest?.overallStatus === "OPEN" ||
                          a.documentRequest?.overallStatus === "PENDING" ||
                          a.documentRequest?.overallStatus === "REJECTED") && (
                          <a
                            className="download-text-document"
                            onClick={() => {
                              getDocumentById(a.documentRequest?.docRequestId);
                              setStatusModalOpen(true);
                              setModalName(a.docName);
                              sessionService.setPreStudentData(
                                a.documentRequest?.docRequestId
                              );
                            }}
                          >
                            Check Status
                          </a>
                        )}
                        {a.isActive === true &&
                          a.docName === "Active Policy" &&
                          a.needVerification === true &&
                          !a.documentRequest && (
                            <a
                              className="download-text-document"
                              onClick={() => {
                                setVerificationModalOpen(true);
                                setModalData(a);
                                setModalName(a.docName);
                              }}
                            >
                              Request verification
                            </a>
                          )}
                        {a.isActive === true &&
                          a.docName === "DLOA" &&
                          a.needVerification === true &&
                          !a.documentRequest && (
                            <a
                              className="download-text-document"
                              onClick={() => {
                                setDloaModalOpen(true);
                                setModalData(a);
                                setModalName(a.docName);
                              }}
                            >
                              Request verification
                            </a>
                          )}
                        {a.isActive === true &&
                          a.docName === "Enrollment Contract" &&
                          a.needVerification === true &&
                          !a.documentRequest && (
                            <a
                              className="download-text-document"
                              onClick={() => {
                                setEnrollmentModalOpen(true);
                                setModalData(a);
                                setModalName(a.docName);
                              }}
                            >
                              Request verification
                            </a>
                          )}

                        {a.docName === "Conditional Letter of Acceptance" && (
                          <a
                            className={`download-text-document ${
                              (admissionData?.cloaStudentPayments.length ===
                                0 ||
                                !admissionData) &&
                              "opacity-50"
                            }`}
                            onClick={() => {
                              if (
                                a.docName ===
                                  "Conditional Letter of Acceptance" &&
                                admissionData?.cloaStudentPayments?.length >
                                  0 &&
                                admissionData
                              ) {
                                handleEyeClick(a.docName, "download");
                              }
                            }}
                          >
                            <BsDownload style={{ marginRight: "4px" }} />
                            {/* <img src={download} style={{ marginRight: "4px" }} /> */}
                            Download
                          </a>
                        )}
                        {a.docName === "Letter of Acceptance" && (
                          <a
                            className={`download-text-document ${
                              (admissionData?.studentPayments.length === 0 ||
                                !admissionData) &&
                              "opacity-50"
                            }`}
                            onClick={() => {
                              if (
                                a.docName === "Letter of Acceptance" &&
                                admissionData?.studentPayments?.length > 0 &&
                                admissionData
                              ) {
                                handleEyeClick(a.docName, "download");
                              }
                            }}
                          >
                            {/* <img src={download} style={{ marginRight: "4px" }} /> */}
                            <BsDownload style={{ marginRight: "4px" }} />
                            Download
                          </a>
                        )}

                        {a.docName === "Active Policy" &&
                          ((a.isActive === true &&
                            a.needVerification === false) ||
                            (a.documentRequest &&
                              a.documentRequest.overallStatus ===
                                "APPROVED")) && (
                            <a
                              className="download-text-document"
                              onClick={() => {
                                handleActiveClick(
                                  a?.documentRequest?.docRequestId
                                );
                              }}
                            >
                              {/* <img
                              src={download}
                              style={{ marginRight: "4px" }}
                            /> */}
                              <BsDownload style={{ marginRight: "4px" }} />
                              Download
                            </a>
                          )}
                        {a.docName === "Enrollment Contract" &&
                          ((a.isActive === true &&
                            a.needVerification === false) ||
                            (a.documentRequest &&
                              a.documentRequest.overallStatus ===
                                "APPROVED")) && (
                            <a
                              className="download-text-document"
                              onClick={() =>
                                handleEnrollClick(
                                  a.documentRequest?.docRequestId
                                )
                              }
                            >
                              {/* <img
                              src={download}
                              style={{ marginRight: "4px" }}
                              alt="Download Icon"
                            /> */}
                              <BsDownload style={{ marginRight: "4px" }} />
                              Download
                            </a>
                          )}
                        {a.docName === "DLOA" &&
                          ((a.isActive === true &&
                            a.needVerification === false) ||
                            (a.documentRequest &&
                              a.documentRequest.overallStatus ===
                                "APPROVED")) && (
                            <a
                              className="download-text-document"
                              onClick={() => {
                                handleEyeClick(a?.docName, "download");
                              }}
                            >
                              {/* <img
                              src={download}
                              style={{ marginRight: "4px" }}
                            /> */}
                              <BsDownload style={{ marginRight: "4px" }} />
                              Download
                            </a>
                          )}

                        {a.isActive === false && (
                          <a className="download-text-document">NA</a>
                        )}
                      </td>
                    )}
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </div>
        <div className="Documents-footer">
          <div className="document-footer-row-container">
            <div>
              <div
                style={{
                  width: "6px",
                  height: "19px",
                  borderRadius: "2px",
                  backgroundColor: "#8C8C8C",
                  marginRight: "5px",
                }}
              ></div>
              <span>Inactive (or) Not Started:</span>
            </div>
            <span>
              Either document is not available yet or student has made no
              request yet
            </span>
          </div>
          <div className="document-footer-row-container">
            <div>
              <div
                style={{
                  width: "6px",
                  height: "19px",
                  borderRadius: "2px",
                  backgroundColor: "#F6413A",
                  marginRight: "5px",
                }}
              ></div>
              <span>Rejected:</span>
            </div>
            <span>Request has been rejected from staff/faculty side</span>
          </div>
          <div className="document-footer-row-container">
            <div>
              <div
                style={{
                  width: "6px",
                  height: "19px",
                  borderRadius: "2px",
                  backgroundColor: "#F69F3A",
                  marginRight: "5px",
                }}
              ></div>
              <span>Pending (or) Open:</span>
            </div>
            <span>Request is pending action from staff/faculty side</span>
          </div>
          <div className="document-footer-row-container">
            <div>
              <div
                style={{
                  width: "6px",
                  height: "19px",
                  borderRadius: "2px",
                  backgroundColor: "#21BC53",
                  marginRight: "5px",
                }}
              ></div>
              <span>Complete:</span>{" "}
            </div>
            <span>Request is complete and document available for download</span>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={false}
        className="crm-modal"
        onRequestClose={() => onClose()}
        isOpen={isVerificationModalOpen}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#333333" }}
          >
            <div className="student-portal-modal-header">
              <span className="student-portal-modal-title">
                Request Verification for{" "}
                <span className="text-white">Active Policy</span>
              </span>
              <IoClose
                color="#ffffff"
                size={"23px"}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex row g-0 gap-2 justify-content-between">
                <div className="col-12 col-sm-6 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Date of Request *
                  </span>
                  <input
                    type="date"
                    style={{ width: "100%" }}
                    className="documents-modal-input-date mt-1"
                    name="Date 0f Request"
                    defaultValue={requestDate}
                    disabled
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Active Policy Contract
                  </span>
                  {!paymentReceipt && (
                    <a
                      onClick={() => setActivePolicyPDF(true)}
                      style={{
                        color: "#F69F3A",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                      className="faculty-student-portal-service-popup-value text-decoration-underline"
                    >
                      Click to Sign the Active Policy
                    </a>
                  )}
                  {paymentReceipt && (
                    <div className="create-list-upload-inner-input-container  mt-4">
                      <div className="column d-flex pe-1 gap-2">
                        {paymentReceipt && (
                          <span
                            className="text-white "
                            style={{
                              backgroundColor: "rgb(15 7 7)",
                              padding: "5px 15px",
                            }}
                          >
                            {paymentReceiptName}
                          </span>
                        )}
                      </div>

                      {paymentReceipt ? (
                        <BiCheck color="green" size={25} />
                      ) : null}

                      {paymentReceipt && (
                        <a
                          href={paymentReceipt}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye
                            color={theme === "light" ? "#000000" : "#ffffff"}
                          />
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Comments/Queries if any{" "}
                    <span style={{ color: "#7B7B7B", fontSize: "12px" }}>
                      (max limit here in chars)
                    </span>{" "}
                    *
                  </span>
                  <textarea
                    className="documents-modal-input-text-area mt-1"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              className="mt-3 pt-3 d-flex gap-3"
              style={{ borderTop: "1px solid #212226", position: "relative" }}
            >
              <button
                className="student-portal-cancel-button"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                className={`student-portal-submit-button ${
                  (!paymentReceipt || !comments) && "opacity-50"
                }`}
                disabled={!paymentReceipt || !comments}
                type="button"
                onClick={() => {
                  handleSubmitRequest();
                  onClose();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={false}
        className="crm-modal"
        onRequestClose={() => onClose()}
        isOpen={isEnrollmentModalOpen}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#333333" }}
          >
            <div className="student-portal-modal-header">
              <span className="student-portal-modal-title">
                Request Verification for{" "}
                <span className="text-white">Enrollment Contract</span>
              </span>

              <IoClose
                color="#ffffff"
                size={"23px"}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex row g-0 gap-2 justify-content-between">
                <div className="col-12 col-sm-6 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Date of Request *
                  </span>
                  <input
                    type="date"
                    style={{ width: "100%" }}
                    className="documents-modal-input-date mt-1"
                    name="Date 0f Request"
                    defaultValue={requestDate}
                    disabled
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Enrolment Contract
                  </span>
                  <a
                    onClick={() => {
                      setEnrollmentPDF(true);
                    }}
                    style={{
                      color: "#F69F3A",
                      marginTop: "10px",
                      cursor: "pointer",
                      opacity: "100%",
                    }}
                    className="faculty-student-portal-service-popup-value text-decoration-underline"
                  >
                    Click to Sign the Enrollment Contract
                  </a>
                  {paymentReceipt && (
                    <div className="create-list-upload-inner-input-container  mt-4">
                      <div className="column d-flex pe-1 gap-2">
                        {paymentReceipt && (
                          <span
                            className="text-white "
                            style={{
                              backgroundColor: "rgb(15 7 7)",
                              padding: "5px 15px",
                            }}
                          >
                            {paymentReceiptName}
                          </span>
                        )}
                      </div>

                      {paymentReceipt ? (
                        <BiCheck color="green" size={25} />
                      ) : null}

                      {paymentReceipt && (
                        <a
                          href={paymentReceipt}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye
                            color={theme === "light" ? "#000000" : "#ffffff"}
                          />
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Comments/Queries if any{" "}
                    <span style={{ color: "#7B7B7B", fontSize: "12px" }}>
                      (max limit here in chars)
                    </span>{" "}
                    *
                  </span>

                  <textarea
                    className="documents-modal-input-text-area mt-1"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              className="mt-3 pt-3 d-flex gap-3"
              style={{ borderTop: "1px solid #212226", position: "relative" }}
            >
              <button
                className="student-portal-cancel-button"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                className={`student-portal-submit-button ${
                  (!paymentReceipt || !comments) && "opacity-50"
                }`}
                disabled={!paymentReceipt || !comments}
                type="button"
                onClick={() => {
                  handleSubmitEnrollmentRequest();
                  onClose();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={false}
        className="crm-modal"
        onRequestClose={() => onClose()}
        isOpen={isDloaModalOpen}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#333333" }}
          >
            <div className="student-portal-modal-header">
              <span className="student-portal-modal-title">
                Request Verification for{" "}
                <span className="text-white">DLOA</span>
              </span>
              <IoClose
                color="#ffffff"
                size={"23px"}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex row g-0 gap-2 justify-content-between">
                <div className="col-12 col-sm-6 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Date of Request *
                  </span>
                  <input
                    type="date"
                    style={{ width: "100%" }}
                    className="documents-modal-input-date mt-1"
                    name="Date 0f Request"
                    defaultValue={requestDate}
                    disabled
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="col-12 col-sm-6 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    DLOA Request Date *
                  </span>
                  <input
                    type="date"
                    style={{ width: "100%" }}
                    className="documents-modal-input-date mt-1"
                    name="Date 0f Request"
                    value={dloaRequestDate}
                    onChange={(e) => setDloaRequestDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Comments/Queries if any{" "}
                    <span style={{ color: "#7B7B7B", fontSize: "12px" }}>
                      (max limit here in chars)
                    </span>{" "}
                    *
                  </span>
                  <textarea
                    className="documents-modal-input-text-area mt-1"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              className="mt-3 pt-3 d-flex gap-3"
              style={{ borderTop: "1px solid #212226", position: "relative" }}
            >
              <button
                className="student-portal-cancel-button"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                className={`student-portal-submit-button ${
                  (!dloaRequestDate || !comments) && "opacity-50"
                }`}
                disabled={!dloaRequestDate || !comments}
                type="button"
                onClick={() => {
                  handleSubmitRequestDLOA();
                  onClose();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="crm-modal"
        isOpen={isStatusModalOpen}
        shouldFocusAfterRender={false}
        shouldReturnFocusAfterClose={false}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#333333" }}
          >
            <div className="student-portal-modal-header">
              <span className="student-portal-modal-title">
                Request Status for{" "}
                <span className="text-white">
                  {modalData?.document?.docName}
                </span>
              </span>

              <IoClose
                color="#ffffff"
                size={"23px"}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="d-flex flex-column gap-2 mt-2">
              <div className="d-flex">
                <div className="col-6 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-label">
                    Date of Request
                  </span>
                  <span className="faculty-student-portal-service-popup-value">
                    {modalData?.requestDate}
                  </span>
                </div>
                <div className="col-6 d-flex flex-column align-items-end">
                  <span className="admin-action-text-document-popup">
                    <BsFillInfoCircleFill
                      className="me-1"
                      color={
                        modalData?.overallStatus === "OPEN"
                          ? "F6413A"
                          : "F69F3A"
                      }
                    />
                    {modalData?.overallStatus === "OPEN" &&
                      "no action from admin yet"}
                    {modalData?.overallStatus === "PENDING" &&
                      "Request under process."}
                    {modalData?.overallStatus === "REJECTED" &&
                      "Pending student action"}
                  </span>
                </div>
              </div>
              {modalData?.document?.docName === "DLOA" && (
                <>
                  <div className="col-6 d-flex flex-column">
                    <span className="faculty-student-portal-service-popup-label">
                      Student Request Date
                    </span>
                    <span className="faculty-student-portal-service-popup-value">
                      {modalData?.studentDate}
                    </span>
                  </div>
                  {modalData?.facultyStatus === "REJECTED" && (
                    <div className="col-6 d-flex flex-column">
                      <span className="faculty-student-portal-service-popup-label">
                        Approver Date
                      </span>
                      <span className="faculty-student-portal-service-popup-value">
                        {modalData?.approverDate}
                      </span>
                    </div>
                  )}
                </>
              )}
              {!(modalData?.document?.docName === "DLOA") && (
                <div className="d-flex">
                  <div className="col-6 d-flex flex-column">
                    <span className="faculty-student-portal-service-popup-label">
                      {modalName}
                    </span>
                    <a
                      href={modalData?.paymentReceipt}
                      target="_blank"
                      style={{ color: "#F69F3A" }}
                      className="faculty-student-portal-service-popup-value text-decoration-underline"
                    >
                      {modalData?.paymentReceiptName}
                    </a>
                  </div>
                </div>
              )}
              <div className="d-flex">
                <div className="d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-label">
                    Comments/Queries
                  </span>
                  <span className="faculty-student-portal-service-popup-value">
                    {modalData?.requestorComment}
                  </span>
                </div>
              </div>

              {modalData?.overallStatus === "REJECTED" && (
                <div className="d-flex">
                  <div className="col-12 d-flex flex-column gap-1">
                    <div className="student-portal-modal-title text-end">
                      Rejected
                    </div>
                    <span className="faculty-student-portal-document-rejected-box">
                      {modalData?.rejectorFacultyComment ||
                        modalData?.rejectorComment}
                    </span>
                    <span
                      className="faculty-student-portal-service-popup-value text-end"
                      style={{ fontSize: "12px" }}
                    >
                      {formatTimestamp(modalData?.updatedOn)}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {modalData?.document?.docName === "DLOA" &&
              modalData?.facultyStatus === "REJECTED" && (
                <div className="d-flex">
                  <div className="col-12 col-sm-6 d-flex flex-column">
                    <span className="faculty-student-portal-service-popup-value">
                      DLOA Request Date *
                    </span>
                    <input
                      type="date"
                      style={{ width: "100%" }}
                      className="documents-modal-input-date mt-1"
                      name="Date 0f Request"
                      value={dloaRequestDate}
                      onChange={(e) => setDloaRequestDate(e.target.value)}
                    />
                  </div>
                </div>
              )}
            {modalData?.overallStatus === "REJECTED" ? (
              <div
                className="mt-2"
                style={{ borderTop: "1px solid #212226", position: "relative" }}
              >
                {!(modalData?.document?.docName === "DLOA") && (
                  <div className="d-flex">
                    <div className="col-12 d-flex flex-column">
                      <span className="faculty-student-portal-service-popup-value mt-1">
                        {modalData?.document?.docName === "Active Policy"
                          ? "Active Policy Contract"
                          : "Enrollment Contract"}
                      </span>
                      {!paymentReceipt && (
                        <a
                          onClick={() => {
                            if (
                              modalData?.document?.docName === "Active Policy"
                            ) {
                              setActivePolicyPDF(true);
                            } else {
                              setEnrollmentPDF(true);
                            }
                          }}
                          style={{
                            color: "#F69F3A",
                            marginTop: "10px",
                            cursor: "pointer",
                            marginBottom: "7px",
                          }}
                          className="faculty-student-portal-service-popup-value text-decoration-underline"
                        >
                          Click to Sign the {modalData?.document?.docName}
                        </a>
                      )}
                      {paymentReceipt && (
                        <div className="create-list-upload-inner-input-container  mt-4">
                          <div className="column d-flex pe-1 gap-2">
                            {paymentReceipt && (
                              <span
                                className="text-white "
                                style={{
                                  backgroundColor: "rgb(15 7 7)",
                                  padding: "5px 15px",
                                }}
                              >
                                {paymentReceiptName}
                              </span>
                            )}
                          </div>

                          {paymentReceipt ? (
                            <BiCheck color="green" size={25} />
                          ) : null}

                          {paymentReceipt && (
                            <a
                              href={paymentReceipt}
                              target="_blank"
                              rel="noreferrer"
                              className="view-eye-icon"
                            >
                              {theme === "light" ? (
                                <FaEye color="black" />
                              ) : (
                                <FaEye />
                              )}
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-12 d-flex flex-column mt-2">
                  <span className="faculty-student-portal-service-popup-value">
                    Comments
                  </span>
                  <input
                    className="documents-modal-input mt-1"
                    value={comments}
                    onChange={(e) => {
                      setComments(e.target.value);
                    }}
                  />
                </div>

                <div className="mt-3">
                  <button
                    className={`student-portal-cancel-button ${
                      (modalData?.document?.docName === "DLOA"
                        ? !comments || !dloaRequestDate
                        : !paymentReceipt || !comments) && "opacity-50"
                    }`}
                    disabled={
                      modalData?.document?.docName === "DLOA"
                        ? !comments || !dloaRequestDate
                        : !paymentReceipt || !comments
                    }
                    onClick={() => {
                      handleUploadReceipt(modalData?.document?.documentId);
                      onClose();
                    }}
                  >
                    Upload receipt
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="mt-2"
                style={{ borderTop: "1px solid #212226", position: "relative" }}
              >
                <div className="mt-3">
                  <button
                    className={`student-portal-cancel-button ${
                      modalData?.overallStatus === "PENDING" &&
                      modalData?.facultyStatus === "REJECTED" &&
                      "opacity-50"
                    }`}
                    type="button"
                    disabled={
                      modalData?.overallStatus === "PENDING" &&
                      modalData?.facultyStatus === "REJECTED"
                    }
                    onClick={() => {
                      addReminder(modalData?.document?.documentId);
                      onClose();
                    }}
                  >
                    Add a reminder
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <div>
        {docName === "Enrollment Contract" && (
          <EnrollmentView
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
          />
        )}
        {docName === "Conditional Letter of Acceptance" && (
          <CLOAForDocument
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
          />
        )}
        {docName === "DLOA" && (
          <DLOADocument
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
          />
        )}
        {docName === "Letter of Acceptance" &&
          (studentType === "OSAP" ? (
            <FLOAForDocument
              downloadType={downloadType}
              close={() => {
                setDownloadType(null);
              }}
              pdfData={pdfData}
            />
          ) : (
            <FLOAInternationalDocument
              downloadType={downloadType}
              close={() => {
                setDownloadType(null);
              }}
              pdfData={pdfData}
            />
          ))}
      </div>
      {loading && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
      {(enrollmentPDF || activePolicyPDF) && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
      {enrollmentPDF && (
        <div style={{ position: "absolute", left: -9999 }}>
          <EnrollmentContract
            onClose={() => {
              setEnrollmentPDF(false);
            }}
            setEnrollmentPdfData={(blob) => setEnrollmentPdfData(blob)}
            data={admissionData}
            studentType={studentType}
          />
        </div>
      )}
      {activePolicyPDF && (
        <div style={{ position: "absolute", left: -9999 }}>
          <ActivePolicyContract
            onClose={() => {
              setActivePolicyPDF(false);
            }}
            setActivePolicyPdfData={(blob) => setActivePolicyPdfData(blob)}
            data={admissionData}
          />
        </div>
      )}{" "}
    </div>
  );
};

export default PreStudentDocuments;
