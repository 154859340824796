import React, { useState } from "react";
import Modal from "react-modal";
import "./EmailVerificationModal.css";
import { useNavigate } from "react-router-dom";
import { AUTH_URLS } from "core/auth/constants/auth-urls";
import { axiosService } from "../../../../services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import twoFactorauthentication1 from "assets/images/two-factor-authentication/two-factor-authentication1.png";
import { toast } from "react-toastify";

function EmailVerificationModal({ isOpen, onClose, resp }) {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  function hasPermission(permission) {
    let userPermissions =
      sessionstorageService?.hasPermissionByButton(permission);
    return userPermissions;
  }

  const verifyOtp = async () => {
    const credentials = {
      userId: resp?.data?.userData?.userId,
      verifyOtp: otp,
    };
    try {
      const response = await axiosService.post(
        `${AUTH_URLS.VERIFY_OTP}`,
        credentials,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        const permissions = resp.headers["permissions"];
        const token = resp.headers["authorization"];
        sessionstorageService.setPermissions(permissions);
        sessionstorageService.setToken(token);
        sessionstorageService.setUserData(resp.data.userData);

        {
          const getData = await axiosService.get(
            `${AUTH_URLS.GET_EMPLOYEE_DETAILS}${resp.data.userData?.userId}`
          );
          if (getData.status === 200) {
            const Data = getData?.data?.data;
            if (Data?.empId) {
              navigate("/profile/my-profile");
            }
          } else {
          }
        }
        if (resp.data.userData?.admissionManagement?.admissionId) {
          sessionstorageService.setAdmissionId(
            resp.data.userData?.admissionManagement?.admissionId
          );
        } else {
          sessionstorageService.setAdmissionId(null);
        }

        if (hasPermission("System Administration: view")) {
          navigate("/user-management");
        } else if (hasPermission("Pre-onBoarding: view")) {
          navigate("/pre-onboarding");
        } else if (hasPermission("Admission Management: view")) {
          navigate("/admission-management");
        } else if (hasPermission("CRM: view")) {
          navigate("/lead-management");
        } else if (hasPermission("Faculty Dashboard: fullAccess")) {
          navigate("/dashboard-management");
        } else if (hasPermission("Student Dashboard: view")) {
          navigate("/dashboard-management");
        } else if (hasPermission("Student Portal: view")) {
          navigate("/student-portal");
        }
      }
    } catch (error) {
      if (error) {
        toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
      }
    }
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldFocusAfterRender={false}
    >
      <div className="modal-Con row">
        <div
          className="col-12 col-md-10 col-lg-6 py-5 p-3 flex-column d-flex flex-md-row"
          style={{
            maxHeight: "100%",
            backgroundColor: "#ffffff",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px 20px",
            }}
          >
            <img
              src={twoFactorauthentication1}
              alt="Moving"
              className="moving-image"
              style={{ width: "100px" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "20px",
              color: "black",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              Two Factor Authentication
            </div>
            <div>
              A verification code has been sent to your email. This code will be
              valid for 15 minutes.
            </div>
            {/* <div>
              <input type="text" placeholder="Please enter the code here" />
            </div> */}
            <div class="verification-modal-input-container">
              <input
                className="verification-modal-input"
                placeholder="Please enter the 6-digit code here."
                type="number"
                inputMode="numeric"
                maxLength="6"
                value={otp}
                onChange={(e) => setOtp(e?.target?.value)}
                onInput={(e) => (e.target.value = e.target.value.slice(0, 6))}
                required
              />
              <button class="verification-modal-verify-btn" onClick={verifyOtp}>
                VERIFY
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EmailVerificationModal;
