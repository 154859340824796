import React from "react";
import EnrollmentView from "./enrollment";
import AcademicTranscript from "./academicTranscript";
import InterimTranscript from "./interimTranscript";
import FinalTranscript from "./finalTranscript";
import WithDrawalLetter from "./withDrawalLetter";
import BreakLetter from "./downloadBreakLetter";
import FeesInvoice from "./feesInvoice";
import OfficialReceipt from "./officialReceipt";
import TravelSupport from "./travelSupport";
import CLOAForDocument from "./cloa";
import FLOAForDocument from "./floa";
import OfficialReceiptProvisional from "./officialReceiptProvisional";
import FLOAInternationalDocument from "./floaInternational";
import CourseCompletion from "./courseCompletion";
import DLOADocument from "./dloa";
import CoOpLetter from "./co-opLetter";
import FinalCertificate from "./finalCertificate";

function DocumentsRoutesForPdf() {
  return (
    <div>
      <EnrollmentView />
      <AcademicTranscript />
      <InterimTranscript />
      <FinalTranscript />
      <WithDrawalLetter />
      <BreakLetter />
      <FeesInvoice />
      <OfficialReceipt />
      <OfficialReceiptProvisional />
      <TravelSupport />
      <CLOAForDocument />
      <FLOAForDocument />
      <FLOAInternationalDocument />
      <CourseCompletion />
      <DLOADocument />
      <CoOpLetter />
      <FinalCertificate />
    </div>
  );
}

export default DocumentsRoutesForPdf;
