import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import sha256 from "crypto-js/sha256";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { useEffect, useState } from "react";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";
import betalogo from "assets/images/icons/beta.svg";
import qrcode from "qrcode";
import { axiosService } from "services/axios.service";

const styles = StyleSheet.create({
  Document: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  qrCodeContainer: {
    backgroundColor: "violet",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
  },
  actualValue: {
    fontSize: 10,
    fontFamily: "Helvetica",
  },
  newText: {
    width: 150,
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    paddingVertical: 2,
    flexWrap: "wrap",
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 1,
  },
  labelText: {
    width: 140,
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    textAlign: "left",
    paddingRight: 10,
  },
  labelText2: {
    width: 220,
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    textAlign: "left",
    paddingRight: 10,
  },
  colonText: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    paddingRight: 8,
  },
  valueText: {
    flex: 1,
    fontSize: 10,
    fontFamily: "Helvetica",
    textAlign: "left",
  },
  h1: {
    fontSize: 14,
    fontFamily: "Helvetica-Bold",
    textDecoration: "underline",
  },
  h5: {
    left: 42,
    right: 40,
    fontSize: 11,
    paddingRight: 60,
    top: 36,
  },
  register: {
    left: 42,
    right: 40,
    fontSize: 11,
    paddingRight: 60,
    top: 46,
  },
  flex: {
    flexDirection: "row",
    left: 40,
    right: 45,
    top: 40,
    justifyContent: "space-between",
  },
  folder: {
    fontSize: 11,
    marginLeft: 40,
    marginRight: 88,
    top: 98,
    fontFamily: "Helvetica-Bold",
  },
  link: {
    fontSize: 11,
    marginLeft: 43,
    top: 78,
    color: "#0563c1",
    textDecoration: "underline",
  },
  body: {
    top: 100,
  },
  bodycontainer: {
    marginLeft: 40,
    marginRight: 30,
  },
  sealImage: {
    width: 80,
    height: 80,
    position: "relative",
    zIndex: 1,
    top: 68,
  },
  signImage: {
    width: 130,
    height: 40,
    position: "relative",
    zIndex: 1,
    top: 65,
    left: 45,
  },
  qrCodeImage: {
    width: 80,
    top: 172,
    height: 82,
    position: "relative",
    zIndex: 1,
  },
  watermarkContainer: {
    position: "absolute",
    top: "40%",
    left: "30%",
    transform: "translate(-50%, -50%) rotate(-45deg)",
    opacity: 0.5,
  },
  watermarkText: {
    fontSize: 80,
    color: "gray",
  },
  footer: {
    position: "absolute",
    bottom: 40,
    left: 50,
    right: 40,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
  },
  otherInfo: {
    fontSize: 11,
    fontFamily: "Helvetica-Bold",
    marginBottom: 1,
  },
  dot: {
    fontSize: 11,
    marginLeft: 15,
    marginBottom: 5,
    fontFamily: "Helvetica",
  },
  table: {
    marginTop: 3,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#000",
  },
  tableRow: {
    flexDirection: "row",
  },

  tableCell1: {
    textAlign: "left",
    padding: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#000",
    width: "30%",
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingLeft: 2,
  },
  tableCell2: {
    fontFamily: "Helvetica",
    fontSize: 10,
    textAlign: "left",
    padding: 1,
    paddingLeft: 2,
    borderBottomWidth: 1,
    borderColor: "#000",
    width: "70%",
  },
});

const Header = () => (
  <View style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
    <Image alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
  </View>
);

const Footer = () => (
  <View style={{ position: "absolute", top: "-60px", left: "-50px", right: 0 }}>
    <Image alt="img" src={footer} style={{ width: "110%", height: "80px" }} />
  </View>
);

const FeesInvoice = ({ downloadType, pdfData, Data }) => {
  const formatProgramName = (programName) => {
    return programName
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const applicationNo = pdfData
    ? pdfData?.programDetails[0]?.applicationNo
    : Data?.master?.admissionManagement?.programDetails[0]?.applicationNo;

  const currentDate = new Date();
  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 3);
  const formattedIssueDate = formatDate(currentDate);
  const Issue = formattedIssueDate;
  const Name = pdfData
    ? pdfData?.personalData?.firstName + " " + pdfData?.personalData?.lastName
    : Data?.master?.admissionManagement?.personalData?.firstName +
      " " +
      Data?.master?.admissionManagement?.personalData?.lastName;
  const StartDate = pdfData
    ? pdfData?.programDetails[0]?.fromDate
    : Data?.master?.admissionManagement?.programDetails[0].fromDate;
  const EndDate = pdfData
    ? pdfData?.programDetails[0]?.toDate
    : Data?.master?.admissionManagement?.programDetails[0].toDate;

  const Program = pdfData?.programDetails[0]?.program
    ? formatProgramName(pdfData.programDetails[0].program)
    : formatProgramName(
        Data?.master?.admissionManagement?.programDetails[0].program
      );
  const Campus = pdfData?.programDetails[0]?.campus
    ? formatProgramName(pdfData.programDetails[0].campus)
    : formatProgramName(
        Data?.master?.admissionManagement?.programDetails[0].campus
      );
  const Student = pdfData?.personalData?.studentId
    ? pdfData?.personalData?.studentId
    : Data?.master?.admissionManagement?.personalData?.studentId;

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (downloadType === "view") {
      generatePdf("view");
    }
    if (downloadType === "download") {
      generatePdf("download");
    }
  }, [downloadType]);
  const generateQRCodeDataURL = async (data) => {
    try {
      const url = await qrcode.toDataURL(data);
      return url;
    } catch (error) {
      console.error("Error generating QR code:", error);
      return "";
    }
  };
  const [hashValueData, setHashValue] = useState(
    sha256(`${"Fee_Invoice"}-${applicationNo}`).toString().substring(0, 20)
  );
  useEffect(() => {
    const uniqueValue = Math.random();
    const inputString = `Fee_Invoice-${applicationNo}-${uniqueValue}`;

    const hashValue = sha256(inputString).toString().substring(0, 20);

    setHashValue(hashValue);
  }, [applicationNo]);
  const generatePdf = async (withWatermark) => {
    try {
      setLoad(true);
      const qrCodeDataURL = await generateQRCodeDataURL(
        `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`
      );
      const pdfBlob = await new Promise((resolve, reject) => {
        pdf(
          <Document>
            <Page size="A4" style={styles.page}>
              {withWatermark === "view" && (
                <View style={styles.watermarkContainer}>
                  <Text style={styles.watermarkText}>SAMPLE</Text>
                </View>
              )}
              <Header />
              <View style={styles.body}>
                <View style={styles.alignment}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginHorizontal: 40,
                      paddingBottom: 5,
                    }}
                  >
                    <Text style={styles.boldText}> Issue Date:</Text>
                    <Text style={styles.actualValue}>{formattedIssueDate}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text style={styles.h1}>FEES INVOICE</Text>
                  </View>
                  <View style={styles.bodycontainer}>
                    <View style={{ paddingTop: 12 }}>
                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>Student Name</Text>
                        <Text style={styles.colonText}>:</Text>
                        <Text style={styles.valueText}>{Name}</Text>
                      </View>
                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>Student Id</Text>
                        <Text style={styles.colonText}>:</Text>
                        <Text style={styles.valueText}>{Student}</Text>
                      </View>

                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>Course</Text>
                        <Text style={styles.colonText}>:</Text>
                        <Text style={styles.valueText}>{Program}</Text>
                      </View>
                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>
                          The Campus Of Study:
                        </Text>
                        <Text style={styles.colonText}>:</Text>
                        <Text style={styles.valueText}>{Campus}</Text>
                      </View>
                    </View>
                    <View style={{ paddingTop: 14 }}>
                      <Text style={[styles.boldText, { fontSize: 11 }]}>
                        First Semester{" "}
                        {Data?.invoiceType
                          ? formatProgramName(Data?.invoiceType)
                          : "Tuition Fee"}
                        : CAD {Data?.invoiceAmount}
                      </Text>
                      <Text style={[styles.boldText, { fontSize: 11 }]}>
                        Break Down:
                      </Text>
                      <View style={{ paddingTop: 3 }}>
                        <View style={styles.textContainer}>
                          <Text style={styles.labelText2}>
                            • First Installment{" "}
                            {Data?.invoiceType
                              ? formatProgramName(Data?.invoiceType)
                              : "Tuition Fee"}{" "}
                            Deposit
                          </Text>
                          <Text style={styles.colonText}>:</Text>
                          <Text style={styles.valueText}>
                            CAD{" "}
                            {Data?.invoiceAmount ? Data?.invoiceAmount : "-"}
                          </Text>
                        </View>
                        <View style={styles.textContainer}>
                          <Text style={styles.labelText2}>
                            • Second Installment{" "}
                            {Data?.invoiceType
                              ? formatProgramName(Data?.invoiceType)
                              : "Tuition Fee"}{" "}
                            Deposit
                          </Text>
                          <Text style={styles.colonText}>:</Text>
                          <Text style={styles.valueText}>
                            {Data?.invoiceAmount ? Data?.invoiceAmount : "-"}
                          </Text>
                        </View>
                        <View style={styles.textContainer}>
                          <Text style={styles.labelText2}>
                            • Deadline to pay the balance fee
                          </Text>
                          <Text style={styles.colonText}>:</Text>
                          <Text style={styles.valueText}>
                            {Data?.paymentMode ? Data?.paymentMode : "-"}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={{ paddingTop: 14 }}>
                      <Text style={[styles.actualValue, { fontSize: 11 }]}>
                        Length of program (YYYY/MM/DD)
                      </Text>
                      <View style={{ paddingTop: 3 }}>
                        <View style={styles.textContainer}>
                          <Text style={styles.labelText}> • Start Date</Text>
                          <Text style={styles.colonText}>:</Text>
                          <Text style={styles.valueText}>{StartDate}</Text>
                        </View>
                        <View style={styles.textContainer}>
                          <Text style={styles.labelText}>
                            • Expected End Date
                          </Text>
                          <Text style={styles.colonText}>:</Text>
                          <Text style={styles.valueText}>{EndDate}</Text>
                        </View>
                      </View>
                    </View>
                    <Text style={[styles.actualValue, { paddingTop: 10 }]}>
                      Bank Account Details:
                    </Text>
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableCell1}>
                          Business Name, Address:
                        </Text>
                        <Text style={styles.tableCell2}>
                          2198859 Ontario Ltd. o/a Beta College of Business and
                          Technology
                        </Text>
                      </View>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableCell1}>Bank Name:</Text>
                        <Text style={styles.tableCell2}>TD Canada Trust</Text>
                      </View>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableCell1}>Bank Address:</Text>
                        <Text style={styles.tableCell2}>
                          1571 Sandhurst Circle, Scarborough, Ontario, M1V 1V2
                        </Text>
                      </View>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableCell1}>Bank Code:</Text>
                        <Text style={styles.tableCell2}>004</Text>
                      </View>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableCell1}>Transit Number:</Text>
                        <Text style={styles.tableCell2}>03302</Text>
                      </View>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableCell1}>Bank Swift Code:</Text>
                        <Text style={styles.tableCell2}>TDOMCATTTOR</Text>
                      </View>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableCell1}>Account Number:</Text>
                        <Text style={styles.tableCell2}>5261133</Text>
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.actualValue, { paddingTop: 12 }]}>
                        Pay online at
                        <Text style={styles.link}>
                          {" "}
                          https://erp.lmsmybeta.com/#/payment
                        </Text>
                        .
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.actualValue, { paddingTop: 10 }]}>
                        For Beta College of Business and Technology:
                      </Text>
                      <Text style={[styles.actualValue]}>Sd/-</Text>
                      <Text style={[styles.actualValue]}>Falguni Patel</Text>

                      <Text style={[styles.boldText]}>
                        Sr. Accounts Officer
                      </Text>
                    </View>

                    <View style={{ paddingTop: 10 }}>
                      <Text style={styles.otherInfo}>
                        Important Information:
                      </Text>
                      <Text style={[styles.dot, { paddingTop: 5 }]}>
                        •{"  "} All fees and payments are subject to Terms and
                        Conditions and actual realization. Claims will only be
                        entertained once the fees/funds are deposited in the
                        college bank account/financial institution. Transfer
                        proof from a financial institution or Official Bank
                        receipt is mandatory to submit an application for a
                        refund. Please visit our website for more details.
                      </Text>
                      <Text style={styles.dot}>
                        •{"  "} This letter can be verified by emailing to
                        finance@mybeta.ca /admin@betacollege.ca
                      </Text>
                      <Text style={styles.dot}>
                        •{"  "} This is a digital document and does not need a
                        signature.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              {withWatermark === "download" && (
                <View>
                  <Image style={styles.qrCodeImage} src={qrCodeDataURL} />
                </View>
              )}

              <View style={styles.footer}>
                <View style={styles.qrCodeContainer}></View>
                <Footer />
              </View>
            </Page>
          </Document>
        )
          .toBlob()
          .then(resolve)
          .catch(reject);
      });

      if (withWatermark === "view") {
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          '<iframe width="100%" height="100%" src="' +
            pdfDataUri +
            '"></iframe>'
        );

        setLoad(false);
      }
      if (withWatermark === "download") {
        if (hashValueData) {
          try {
            const fileName = `${
              withWatermark === "view" ? "Fee_Invoice-sample" : "Fee_Invoice"
            }_${applicationNo}.pdf`;
            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });

            const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

            const formData = new FormData();
            formData.append("file", pdfFile);

            const response = await axiosService.postFile(url, pdfFile, {});

            const byteCharacters = atob(response.data.data.body);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = urlBlob;
            a.download = "Fee_Invoice.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);
            setLoad(false);
          } catch (error) {
            console.error("Error:", error);
            setLoad(false);
          }
        }
        setLoad(false);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      setLoad(false);
    }
  };

  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
    </span>
  );
};

export default FeesInvoice;
