import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import sha256 from "crypto-js/sha256";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { useEffect, useState } from "react";
import seal from "assets/images/Beta_seal.png";
import signature from "assets/images/Rahul_Trivedi_Signature.png";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";
import betalogo from "assets/images/icons/beta.svg";
import qrcode from "qrcode";
import { axiosService } from "services/axios.service";

const styles = StyleSheet.create({
  Document: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  departmentfolder: {
    fontSize: 11,
    marginLeft: 40,
    marginRight: 88,
    top: 98,
    fontFamily: "Helvetica-Bold",
  },
  page2: {
    fontSize: 11,
    marginLeft: 39,
    top: -5,
    fontFamily: "Helvetica-Bold",
  },
  Date: {
    top: 27,
    fontSize: 11,
    fontFamily: "Helvetica-Bold",
    marginLeft: 40,
    marginRight: 40,
    paddingBottom: 10,
  },
  Block: {
    top: 50,
    fontSize: 11,
    marginLeft: 40,
    marginRight: 40,
    paddingBottom: 10,
  },
  letter: {
    top: 55,
    fontSize: 11,
    marginLeft: 40,
    marginRight: 40,
    paddingBottom: 10,
  },
  Beta: {
    top: 56,
    fontSize: 11,
    marginLeft: 40,
    marginRight: 40,
    paddingBottom: 10,
  },
  deposit1: {
    top: 63,
    fontSize: 11,
    marginLeft: 40,
    marginRight: 40,
    paddingBottom: 10,
  },
  applicable: {
    top: 63,
    fontSize: 11,
    marginLeft: 40,
    marginRight: 40,
    paddingBottom: 10,
  },
  qrCodeContainer: {
    backgroundColor: "violet",
  },
  styles1: {
    top: 45,
  },
  link3: {
    fontSize: 10,
    marginBottom: 20,
    position: "fixed",
    flexWrap: "wrap",
    color: "#0563c1",
    fontFamily: "Helvetica-Bold",
    textDecoration: "underline",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  page2Main: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
    paddingRight: 40,
  },
  top: {
    left: 40,
    right: 40,
    color: "grey",
  },
  heading: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 10,
    position: "fixed",
    flexWrap: "wrap",
    fontFamily: "Helvetica-Bold",
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    position: "fixed",
    flexWrap: "wrap",
    fontFamily: "Helvetica-Bold",
    top: -8,
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
  },
  actualValue: {
    fontSize: 10,
    fontFamily: "Helvetica",
  },
  h1: {
    fontSize: 14,
    fontFamily: "Helvetica-Bold",
    textDecoration: "underline",
  },
  h2: {
    left: 40,
    right: 40,
    fontSize: 9,
    color: "red",
    paddingRight: 60,
    fontFamily: "Helvetica-Bold",
  },
  h3: {
    fontSize: 11,
    paddingRight: 60,
    paddingBottom: 5,
    fontFamily: "Helvetica-Bold",
  },
  h4: {
    left: 42,
    right: 40,
    fontSize: 11,
    paddingRight: 60,
    top: 16,
  },
  h5: {
    left: 42,
    right: 40,
    fontSize: 11,
    paddingRight: 60,
    top: 36,
  },
  register: {
    left: 42,
    right: 40,
    fontSize: 11,
    paddingRight: 60,
    top: 46,
    fontFamily: "Helvetica-Bold",
  },
  h6: {
    left: 42,
    right: 38,
    fontSize: 11,
    paddingRight: 60,
    top: 56,
  },
  h7: {
    fontSize: 11,
    top: 60,
  },
  rightAlignedText: {
    fontSize: 11,
    alignSelf: "flex-end",
    marginBottom: 5,
    paddingRight: 80,
    fontFamily: "Helvetica-Bold",
  },
  flex: {
    flexDirection: "row",
    left: 40,
    right: 45,
    top: 40,
    justifyContent: "space-between",
  },
  headertable: {
    top: 10,
    fontSize: 12,
    color: "white",
    marginBottom: 10,
    borderTop: "1",
    borderRight: "1",
    borderLeft: "1",
    marginLeft: 40,
    marginRight: 40,
    backgroundColor: "#2F5496",
    fontFamily: "Helvetica-Bold",
    paddingTop: 1,
    paddingLeft: 3,
  },
  infoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 5,
  },
  Signature: {
    fontSize: 11,
    marginLeft: 39,
    top: 15,
    fontFamily: "Helvetica-Bold",
  },
  folder: {
    fontSize: 11,
    marginLeft: 40,
    marginRight: 88,
    top: 98,
    fontFamily: "Helvetica-Bold",
  },
  page2: {
    fontSize: 13,
    marginLeft: 39,
    top: -5,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    textDecoration: "underline",
  },
  Signature1: {
    fontSize: 11,
    marginLeft: 39,
    top: 20,
    fontFamily: "Helvetica-Bold",
  },
  otherInfo: {
    fontSize: 11,
    marginLeft: 39,
    top: 15,
    fontFamily: "Helvetica-Bold",
    marginBottom: 2,
  },
  needInfo: {
    fontSize: 11,
    marginLeft: 39,
    top: 30,
    fontFamily: "Helvetica-Bold",
    marginBottom: 10,
  },
  dot: {
    fontSize: 11,
    marginLeft: 59,
    top: 30,
    marginBottom: 5,
  },
  link: {
    fontSize: 11,
    marginLeft: 43,
    top: 78,
    color: "#0563c1",
    textDecoration: "underline",
  },
  link1: {
    fontSize: 10,
    marginLeft: 43,
    top: 78,
    color: "#0563c1",
  },
  logo: {
    width: 130,
    height: 40,
    marginLeft: "74%",
    resizeMode: "cover",
  },
  flexWrap: {
    top: 10,
  },
  border: {
    borderRightWidth: 1,
    borderLeft: 1,
    borderBottom: 1,
    borderColor: "black",
    marginLeft: 40,
    marginRight: 40,
  },
  borderfull: {
    borderWidth: 1,
    borderColor: "black",
    marginLeft: 40,
    marginRight: 40,
  },
  Condition: {
    borderRightWidth: 1,
    borderLeft: 1,
    borderBottom: 1,
    borderTop: 1,
    marginTop: 10,
    borderColor: "black",
    marginLeft: 40,
    marginRight: 40,
    // paddingBottom: 14,
  },
  infoValue: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    flexDirection: "row",
  },
  Notes: {
    fontSize: 10,

    flexDirection: "row",
  },
  Programs: {
    width: "65%",
    fontSize: 10,
    flexDirection: "row",
    top: 19,
    right: 160,
  },
  separator: {
    borderBottomWidth: 1,
    marginTop: 10,
    marginBottom: 10,
  },
  verticalLine: {
    borderRightWidth: 1,
    borderColor: "black",
    height: "100",
  },
  right: {
    borderRightWidth: 1,
    marginLeft: 130,
    justifyContent: "flex-end",
  },
  table: {
    marginLeft: 40,
    marginRight: 40,
    top: 10,
    borderWidth: 1,
    fontSize: 9,
  },
  table1: {
    marginLeft: 40,
    marginRight: 40,
    borderWidth: 1,
    fontSize: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  tableCol: {
    width: "50%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    paddingLeft: 3,
    paddingVertical: 2,
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
  },
  tableColfull: {
    width: "100%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    paddingLeft: 3,
    paddingVertical: 2,
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
  },
  RegisteredtableCol: {
    width: "50%",
    borderRightWidth: 1,
    borderRightColor: "#000",

    paddingLeft: 3,
    fontSize: 10,
    fontFamily: "Times-Italic",
  },
  RegisteredtableCol1: {
    width: "50%",
    borderRightWidth: 1,
    borderRightColor: "#000",

    paddingLeft: 3,
    fontSize: 11,
  },
  Registeredaligment: {
    top: 20,
  },
  Registered: {
    fontFamily: "Helvetica-Bold",
  },
  body: {
    top: 100,
  },
  sealImage: {
    width: 110,
    height: 110,
    position: "relative",
    zIndex: 1,
    top: 85,
  },
  signImage: {
    width: 130,
    height: 37,
    position: "relative",
    zIndex: 1,
    top: 90,
    left: 45,
  },
  qrCodeImage: {
    width: 80,
    top: 256,
    height: 82,
    position: "relative",
    zIndex: 1,
  },
  qrCodeImageFor2: {
    width: 80,
    top: 350,
    height: 82,
    position: "relative",
    zIndex: 1,
  },
  qrCodeImageFor3: {
    width: 80,
    top: 344,
    height: 82,
    position: "relative",
    zIndex: 1,
  },
  qrCodeImageFor4: {
    width: 80,
    top: 233,
    height: 82,
    position: "relative",
    zIndex: 1,
  },
  alignment: {
    // top: -17,
  },
  tablealigment: {
    top: 50,
  },
  tablealigment2: {
    top: 65,
  },
  newText: {
    borderTopWidth: 1,
    borderTopColor: "#000",
    paddingLeft: 3,
    paddingVertical: 2,
  },
  newText1: {
    borderTopWidth: 1,
    borderTopColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    paddingLeft: 3,
    paddingVertical: 2,
  },
  watermarkContainer: {
    position: "absolute",
    top: "40%",
    left: "30%",
    transform: "translate(-50%, -50%) rotate(-45deg)",
    opacity: 0.5,
  },
  watermarkText: {
    fontSize: 80,
    color: "gray",
  },
  tableColNew: {
    width: "50%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
  },
  subText: {
    fontWeight: "normal",
  },
  footer: {
    position: "absolute",
    bottom: 40,
    left: 50,
    right: 40,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
  },
  dloaText1: {
    top: 30,
    fontSize: 11,
    marginLeft: 40,
    marginRight: 40,
    paddingBottom: 10,
  },
  dloaText2: {
    top: 32,
    fontSize: 11,
    marginLeft: 40,
    marginRight: 40,
    paddingBottom: 10,
  },
});

const HeaderForFloa = () => (
  <View style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
    <Image alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
  </View>
);

const FooterForFloa = () => (
  <View style={{ position: "absolute", top: "-60px", left: "-50px", right: 0 }}>
    <Image alt="img" src={footer} style={{ width: "110%", height: "80px" }} />
  </View>
);

const FloaInternationalDocument = ({ downloadType, pdfData }) => {
  const formatProgramName = (programName) => {
    return programName
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const applicationNo = pdfData?.programDetails[0]?.applicationNo;
  const currentDate = new Date();
  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 3);
  const formattedIssueDate = formatDate(currentDate);
  const Issue = formattedIssueDate;
  const Expiry = pdfData?.programDetails[0]?.expirationAcceptanceLetter
    ? pdfData?.programDetails[0]?.expirationAcceptanceLetter
    : "-";
  const Name =
    pdfData?.personalData?.firstName + " " + pdfData?.personalData?.lastName;
  const StartDate = pdfData?.programDetails[0]?.fromDate;
  const EndDate = pdfData?.programDetails[0]?.toDate;
  const StudyLevel = pdfData?.programDetails[0]?.studyLevel;
  const HoursPerWeek = pdfData?.programDetails[0]?.hoursPerWeek;
  const ProgramDuration = pdfData?.programDetails[0]?.programDuration;
  const Campus = pdfData?.programDetails[0]?.campus;
  const creditHours = ProgramDuration * HoursPerWeek;
  const Program = pdfData?.programDetails[0]?.program
    ? formatProgramName(pdfData.programDetails[0].program)
    : "-";
  const Status =
    (pdfData?.programDetails[0]?.academicStatus === "FULLTIME"
      ? "Full Time"
      : pdfData?.programDetails[0]?.academicStatus === "PARTTIME"
      ? "Part Time"
      : "") +
    ", " +
    (pdfData?.programDetails[0]?.programType === "NONVOCATIONAL"
      ? "Non-Vocational"
      : pdfData?.programDetails[0]?.programType === "VOCATIONALANDACADEMIC"
      ? "Vocational & Academic"
      : "");

  const Gender = pdfData?.personalData?.sex;
  const ContactNo = pdfData?.mailingAddress?.mobile1;
  const Student = pdfData?.personalData?.studentId
    ? pdfData?.personalData?.studentId
    : "-";

  const Mailing1 = pdfData?.mailingAddress?.streetNo;
  const Mailing2 = pdfData?.mailingAddress?.street;
  const Mailing3 = pdfData?.mailingAddress?.city;
  const Mailing4 = pdfData?.mailingAddress?.postalCode;
  const Mailing5 = pdfData?.mailingAddress?.country;
  const DOB = pdfData?.personalData?.dateOfBirth;
  const College = "Beta College of Business and Technology";
  const AddressOfCollege =
    "Brampton Campus: 1 Nelson Street West, Unit 200A, Brampton, ON, L6X 3E4, Canada.\nMississauga Campus: 4275 Village Centre Court, Suite No. 201, Mississauga, ON, L4Z 1V3, Canada.";
  const PreAddressOfCollege =
    "10 Gillingham Drive, Suite No. 201, Brampton ON, L6X 5A5, Canada.  ";
  const Contact1 = "Richa Jaiswal";
  const Position1 = "Director of International Admission";
  const mobileNo1 = "(647) 573 3660";
  const Contact2 = "Dr. Pooja Trivedi";
  const Position2 = "Director of Studies";
  const mobileNo2 = "(905) 497 9999";
  const DLI = "0124136581227";
  const Email = "hello@mybeta.ca";
  const applicationFee = pdfData?.fees[0]?.applicationFee;
  const tuitionFee = pdfData?.fees[0]?.tuitionFee;
  const bankFee = pdfData?.fees[0]?.bankFee;
  const internationalFee = pdfData?.fees[0]?.internationalFee;
  const materialFee = pdfData?.fees[0]?.materialFee;
  const eslFee = pdfData?.fees[0]?.eslFee;
  const otherFee = pdfData?.fees[0]?.otherFee;
  const fieldTripsFee = pdfData?.fees[0]?.fieldTripsFee;
  const ConditionForAcceptance = pdfData?.cloaStudentPayments[0]
    ?.conditionsOfAcceptance
    ? pdfData?.cloaStudentPayments[0]?.conditionsOfAcceptance
    : "-";
  const Notes = pdfData?.cloaStudentPayments[0]?.notes
    ? pdfData?.cloaStudentPayments[0]?.notes
    : "-";

  const Date2 = formattedIssueDate;
  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (downloadType === "view") {
      generatePdf("view");
    }
    if (downloadType === "download") {
      generatePdf("download");
    }
  }, [downloadType]);
  const generateQRCodeDataURL = async (data) => {
    try {
      const url = await qrcode.toDataURL(data);
      return url;
    } catch (error) {
      console.error("Error generating QR code:", error);
      return "";
    }
  };
  const [hashValueData, setHashValue] = useState(
    sha256(`${"FLOAINT"}-${applicationNo}`).toString().substring(0, 20)
  );
  useEffect(() => {
    const uniqueValue = Math.random();
    const inputString = `FLOAINT-${applicationNo}-${uniqueValue}`;

    const hashValue = sha256(inputString).toString().substring(0, 20);

    setHashValue(hashValue);
  }, [applicationNo]);
  const generatePdf = async (withWatermark) => {
    try {
      setLoad(true);
      const qrCodeDataURL = await generateQRCodeDataURL(
        `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`
      );
      const pdfBlob = await new Promise((resolve, reject) => {
        pdf(
          <Document>
            <Page size="A4" style={styles.Document}>
              {withWatermark === "view" && (
                <View style={styles.watermarkContainer}>
                  <Text style={styles.watermarkText}>SAMPLE</Text>
                </View>
              )}
              <HeaderForFloa />

              <View style={styles.styles1}>
                <View style={styles.Date}>
                  <Text>
                    <Text style={styles.FirstName}>{Date2}</Text>
                  </Text>
                </View>
                <View style={styles.Date}>
                  <Text>
                    Student ID: <Text style={styles.FirstName}>{Student}</Text>
                  </Text>
                </View>
                <View>
                  <Text style={styles.Block}>
                    Dear <Text style={{ fontWeight: "bold" }}>{Name},</Text>
                  </Text>
                </View>
                <Text style={styles.letter}>
                  I am delighted to confirm that your recent application to
                  study at the Beta College of Business and Technology has been
                  successful.
                </Text>

                <Text style={styles.Beta}>
                  Beta College takes great care in selecting incoming students
                  from the pool of unique applications. As a student at Beta
                  College, you will join a group of students from various
                  countries who choose us for very many reasons. I am confident
                  you will harness your passion and drive to contribute to our
                  vibrant Greater Toronto community.
                </Text>

                <Text style={styles.deposit1}>
                  During your time here, you will be studying extensive courses
                  related to language, IT skills, quantitative methods,
                  management, accounting, supply chain, operations, database
                  management, and many exciting modules related to your diploma
                  program. All course outlines are provided in detail at
                  <Text style={styles.link1}> https://mybeta.ca/</Text>
                </Text>
                <Text style={styles.deposit1}>
                  As an international student, you are required to apply for a
                  Student Visa. This can be done through your Canadian embassy
                  using this Acceptance letter. Further information regarding
                  this process is available at
                  <Text style={styles.link1}>www.cic.gc.ca</Text> . Since the
                  process may take over a month to complete, we advise applying
                  soon. Canadian Immigration should issue your visa for the
                  duration of the course.
                </Text>

                <Text style={styles.applicable}>
                  Once all the Visa formalities have been completed and you have
                  made your travel arrangements, we request that you let us know
                  at the earliest so we may assist you in banking, housing,
                  airport pick-up, and other services.
                </Text>

                <Text style={styles.applicable}>
                  Please do not hesitate to contact us if you have any further
                  questions. We look forward to meeting you and welcoming you to
                  Canada.
                </Text>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <View>
                    <Text style={[styles.h5, { top: 72 }]}>
                      Yours Sincerely,
                    </Text>
                    <Text style={[styles.h5, { top: 85 }]}>
                      Signature of Institute Representative:
                    </Text>
                    <Image style={styles.signImage} src={signature} />

                    <Text style={[styles.h5, { top: 105 }]}>
                      Print Name of Institute Representative:
                      <Text style={styles.register}>Krunal Trivedi</Text>
                    </Text>

                    <Text style={[styles.register, { top: 115 }]}>
                      Registrar / Director of Admissions & Outreach
                    </Text>
                  </View>
                  <View>
                    <Image style={styles.sealImage} src={seal} />
                  </View>
                </View>
              </View>
              {withWatermark === "download" && (
                <View>
                  <Image style={styles.qrCodeImage} src={qrCodeDataURL} />
                </View>
              )}

              <View style={styles.footer}>
                <View style={styles.qrCodeContainer}></View>
                <FooterForFloa />
              </View>
            </Page>
            <Page size="A4" style={styles.page}>
              {withWatermark === "view" && (
                <View style={styles.watermarkContainer}>
                  <Text style={styles.watermarkText}>SAMPLE</Text>
                </View>
              )}
              <HeaderForFloa />
              <View style={styles.body}>
                <View style={styles.alignment}>
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text style={styles.h1}>Letter Of Acceptance</Text>
                  </View>

                  <View>
                    <Text style={styles.dloaText1}>
                      The following information is included to facilitate your
                      application for the Study Permit, Student Visa, and/or
                      Co-op Work Permit (for applicable programs only) with the
                      Canadian Embassy, Consulate, or Higher Commission. Please
                      make sure to include it with your application.
                    </Text>
                  </View>

                  <View style={styles.flex}>
                    <Text style={styles.h3}>
                      Date of Issue: <Text>{Issue}</Text>
                    </Text>
                    <Text style={styles.rightAlignedText}>
                      Date of Expiry: <Text>{Expiry}</Text>
                    </Text>
                  </View>
                  <View style={styles.tablealigment}>
                    <Text style={styles.headertable}>STUDENT INFORMATION</Text>
                    <View style={styles.table1}>
                      <View style={styles.tableRow}>
                        <View style={styles.tableColNew}>
                          <Text style={styles.newText}>
                            Student Name:
                            <Text style={styles.actualValue}>{Name}</Text>
                          </Text>
                          <Text style={styles.newText}>
                            DOB:
                            <Text style={styles.actualValue}> {DOB}</Text>
                          </Text>
                          <Text style={styles.newText}>
                            Gender:
                            <Text style={styles.actualValue}> {Gender}</Text>
                          </Text>
                          <Text style={styles.newText}>
                            Contact Number:
                            <Text style={styles.actualValue}> {ContactNo}</Text>
                          </Text>
                        </View>

                        <View
                          style={[
                            styles.tableColNew,
                            { flexDirection: "column" },
                          ]}
                        >
                          <Text style={{ paddingLeft: 3 }}>
                            Mailing Address:
                          </Text>
                          <Text
                            style={[styles.actualValue, { paddingLeft: 3 }]}
                          >
                            {Mailing1},{Mailing2}
                          </Text>

                          <Text
                            style={[styles.actualValue, { paddingLeft: 3 }]}
                          >
                            {Mailing3}
                          </Text>
                          <Text
                            style={[styles.actualValue, { paddingLeft: 3 }]}
                          >
                            {Mailing4}
                          </Text>
                          <Text
                            style={[styles.actualValue, { paddingLeft: 3 }]}
                          >
                            {Mailing5}
                          </Text>
                        </View>
                      </View>

                      <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                          <Text>
                            Status:
                            <Text style={styles.actualValue}>{Status}</Text>
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text>
                            Student ID:
                            <Text style={styles.actualValue}>{Student}</Text>
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.tablealigment2}>
                    <Text style={styles.headertable}>
                      INSTITUIONAL INFORMATION
                    </Text>
                    <View style={styles.table1}>
                      <View></View>
                      <View style={styles.tableRow}>
                        <View style={styles.RegisteredtableCol1}>
                          <Text style={styles.Registered}>
                            Type of Instituition:
                            <Text style={styles.actualValue}></Text>
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text>
                            DLI Number:
                            <Text style={styles.actualValue}>{DLI}</Text>
                          </Text>
                        </View>
                      </View>
                      <View style={styles.tableRow}>
                        <View style={styles.RegisteredtableCol}>
                          <Text style={styles.Registered}>
                            Name of Institution:{" "}
                          </Text>
                          <Text style={styles.actualValue}> {College}</Text>
                        </View>
                        <View style={styles.RegisteredtableCol1}>
                          <Text style={styles.boldText}>
                            The Campus of Study:
                            <Text style={styles.actualValue}>{Campus}</Text>
                          </Text>
                        </View>
                      </View>
                      <View style={styles.tableRow}>
                        <View style={styles.tableColfull}>
                          <Text>Address of Institutuion:</Text>
                          <Text style={styles.actualValue}>
                            {" "}
                            {AddressOfCollege}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.tableRow}>
                        <View style={styles.tableColfull}>
                          <Text>*Previous Address of Institution:</Text>
                          <Text style={styles.actualValue}>
                            {" "}
                            {PreAddressOfCollege}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                          <Text>
                            Website
                            <Text style={styles.link1}> www.mybeta.ca</Text>
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text>
                            Email:
                            <Text style={styles.actualValue}> {Email}</Text>
                          </Text>
                        </View>
                      </View>
                      <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                          <Text>
                            Name of Contact:
                            <Text style={styles.actualValue}> {Contact1}</Text>
                          </Text>
                          <Text>
                            Position:
                            <Text style={styles.actualValue}>{Position1}</Text>
                          </Text>
                          <Text>
                            Telephone No:{" "}
                            <Text style={styles.actualValue}>{mobileNo1}</Text>
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text>
                            Name of Contact:
                            <Text style={styles.actualValue}> {Contact2}</Text>
                          </Text>
                          <Text>
                            Position:
                            <Text style={styles.actualValue}>{Position2}</Text>
                          </Text>
                          <Text>
                            Telephone No:{" "}
                            <Text style={styles.actualValue}>{mobileNo2}</Text>
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              {withWatermark === "download" && (
                <View>
                  <Image style={styles.qrCodeImageFor2} src={qrCodeDataURL} />
                </View>
              )}

              <View style={styles.footer}>
                <View style={styles.qrCodeContainer}></View>
                <FooterForFloa />
              </View>
            </Page>
            <Page size="A4" style={styles.page}>
              {withWatermark === "view" && (
                <View style={styles.watermarkContainer}>
                  <Text style={styles.watermarkText}>SAMPLE</Text>
                </View>
              )}
              <HeaderForFloa />
              <View style={styles.body}>
                <View style={styles.alignment}>
                  <Text style={styles.headertable}>PROGRAM INFORMATION</Text>
                  <View style={styles.table1}>
                    <View></View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableColNew}>
                        <Text style={styles.newText}>
                          Program Name:
                          <Text
                            style={[styles.actualValue, { flexWrap: "wrap" }]}
                          >
                            {Program}
                          </Text>
                        </Text>
                        <Text style={styles.newText}>
                          Start Date:
                          <Text style={styles.actualValue}>{StartDate}</Text>
                        </Text>

                        <Text style={styles.newText}>
                          End Date:
                          <Text style={styles.actualValue}>{EndDate}</Text>
                        </Text>
                        <Text style={styles.newText}>
                          Level of Study:
                          <Text style={styles.actualValue}>{StudyLevel}</Text>
                        </Text>
                        <Text style={styles.newText}>
                          Credit Hours :
                          <Text style={styles.actualValue}>
                            {creditHours} Hours / {ProgramDuration} Weeks
                          </Text>
                        </Text>
                        <Text style={styles.newText1}>
                          Hours of Instruction per week:
                          <Text style={styles.actualValue}>{HoursPerWeek}</Text>
                        </Text>
                      </View>
                      <View style={styles.RegisteredtableCol1}>
                        <Text style={styles.Registered}> Fee Structure: </Text>

                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          Application Fee:
                          <Text> {applicationFee}</Text>
                        </Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          Tuition Fee:
                          <Text> {tuitionFee}</Text>
                        </Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          Material/Book Fee:
                          <Text> {materialFee}</Text>
                        </Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          International Fee:
                          <Text> {internationalFee}</Text>
                        </Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          Bank Fee:
                          <Text> {bankFee}</Text>
                        </Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          ESL Fee:
                          <Text> {eslFee}</Text>
                        </Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          Field Trips Fee:
                          <Text> {fieldTripsFee}</Text>
                        </Text>
                        <Text style={[styles.actualValue, { paddingLeft: 3 }]}>
                          Other Fee:
                          <Text> {otherFee}</Text>
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.Condition}>
                    <View style={styles.infoContainer}>
                      <View style={styles.column1}>
                        <Text style={styles.infoValue}>
                          Conditions For Acceptance:{" "}
                        </Text>
                        <Text style={styles.Notes}>
                          {ConditionForAcceptance}
                        </Text>
                      </View>
                      <View style={styles.rightColumn1}></View>
                    </View>
                  </View>
                  <View style={styles.border}>
                    <View style={styles.infoContainer}>
                      <View style={styles.column1}>
                        <Text style={styles.infoValue}>
                          Other relevant information :{" "}
                        </Text>
                        <Text style={styles.Notes}>{Notes}</Text>
                      </View>
                      <View style={styles.rightColumn1}></View>
                    </View>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View>
                      <Text style={[styles.h5, { top: 60 }]}>
                        Signature of Institute Representative:
                      </Text>
                      <Image
                        style={[styles.signImage, { top: 67 }]}
                        src={signature}
                      />

                      <Text style={[styles.h5, { top: 80 }]}>
                        Print Name of Institute Representative:
                        <Text style={styles.register}>Krunal Trivedi</Text>
                      </Text>

                      <Text style={[styles.register, { top: 90 }]}>
                        Registrar / Director of Admissions & Outreach
                      </Text>
                    </View>
                    <View>
                      <Image
                        style={[styles.sealImage, { top: 58 }]}
                        src={seal}
                      />
                    </View>
                  </View>
                  <View style={[styles.borderfull, { top: 115 }]}>
                    <View style={styles.infoContainer}>
                      <View style={styles.column1}>
                        <Text style={[styles.infoValue, { paddingBottom: 5 }]}>
                          Please Note:
                        </Text>
                        <Text style={styles.Notes}>
                          To verify this letter or confirm the authenticity of
                          the content, kindly email{" "}
                          <Text style={styles.link1}>hello@mybeta.ca</Text> or
                          <Text style={styles.link1}>
                            info@betacollege.ca
                          </Text>{" "}
                          and attach this document. All the dates are in
                          YYYY/MM/DD format.
                        </Text>
                      </View>
                      <View style={styles.rightColumn1}></View>
                    </View>
                  </View>
                </View>
              </View>
              {withWatermark === "download" && (
                <View>
                  <Image style={styles.qrCodeImageFor3} src={qrCodeDataURL} />
                </View>
              )}

              <View style={styles.footer}>
                <View style={styles.qrCodeContainer}></View>
                <FooterForFloa />
              </View>
            </Page>

            <Page size="A4" style={styles.page2Main}>
              {withWatermark === "view" && (
                <View style={styles.watermarkContainer}>
                  <Text style={styles.watermarkText}>SAMPLE</Text>
                </View>
              )}
              <HeaderForFloa />
              <View style={styles.body}>
                <View>
                  <Text style={styles.page2}>INFORMATION TO STUDENTS</Text>
                </View>

                <View>
                  <Text style={styles.needInfo}>Payment of Fees</Text>
                  <Text style={styles.h5}>
                    A complete list of payment options can be found on the Beta
                    College website at:
                    <Text style={styles.link}>
                      https://mybeta.ca/payment-options/{" "}
                    </Text>
                  </Text>
                  <Text style={styles.h5}>
                    A complete guide to the Withdrawals and Refund can be found
                    on the Beta College website at:
                    <Text style={styles.link}>
                      https://mybeta.ca/withdrawal-and-refund-policies/{" "}
                    </Text>
                  </Text>
                </View>
                <View style={{ top: 40 }}>
                  <Text style={styles.otherInfo}>
                    Other Relevant Information
                  </Text>

                  <Text style={styles.dot}>
                    • Dates, program availability, and Tuition fees, as stated,
                    are subject to change for each academic year. The fees
                    stated above are based on the previous year. The fees listed
                    cover only the specified number of semesters and may not
                    cover the entire program duration.
                  </Text>
                  <Text style={styles.dot}>
                    • If the student’s English is below the minimum program
                    requirement, the student will be placed into an English
                    Language program or a foundation program (if necessary)
                    before commencing the intended program of study. An
                    additional charge will be applicable for the same.
                  </Text>
                  <Text style={styles.dot}>
                    • The college reserves the right to withdraw confirmed
                    applicants who have not paid their fees or deposits by the
                    due date mentioned in the letter.
                  </Text>
                  <Text style={styles.dot}>
                    • The college reserves the right to inform relevant
                    authorities if any document(s) found counterfeit or
                    fraudulent. The college will take all necessary action/steps
                    to report this to relevant provincial or federal
                    authorities.
                  </Text>
                  <Text style={styles.dot}>
                    • No alteration can be made to this document. If found, this
                    document will be considered Null and Void.
                  </Text>
                </View>

                <View style={{ top: 60 }}>
                  <Text style={styles.needInfo}>
                    Before You Leave Your Home and Come to Canada
                  </Text>

                  <Text style={styles.dot}>
                    • Our team is here to support you from the moment you choose
                    Beta College of Business and Technology until you graduate.
                    We offer resources and support before you arrive or are
                    already a resident in Canada and look forward to being a
                    friendly face to welcome you on campus. Please check on and
                    arrange for the following points:
                  </Text>
                  <Text style={styles.dot}>
                    • Arrange for Housing – Please ensure you have accommodation
                    arranged for yourself.
                  </Text>
                  <Text style={styles.dot}>
                    • Arrange for Airport Transfer – Make your airport pick-up
                    reservation.
                  </Text>
                  <Text style={styles.dot}>
                    • Health Insurance – Be sure you are adequately covered with
                    insurance coverage to cover the medical expenses/bills.
                  </Text>
                </View>
                <View style={{ top: 80 }}>
                  <Text style={styles.needInfo}>Need Assistance?</Text>

                  <Text style={styles.dot}>
                    • Please feel free to contact us at +1(905) 848 5050 /
                    +1(905) 497 9999 or email us at{" "}
                    <Text style={styles.link}>admin@mybeta.ca</Text> for any
                    assistance required. (Calls and emails will be responded to
                    only during office hours.)
                  </Text>
                  <Text style={styles.dot}>
                    • WhatsApp Text only in the case of Emergency: +1 (647) 573
                    3660
                  </Text>
                </View>
              </View>
              {withWatermark === "download" && (
                <View>
                  <Image style={styles.qrCodeImageFor4} src={qrCodeDataURL} />
                </View>
              )}

              <View style={styles.footer}>
                <View style={styles.qrCodeContainer}></View>
                <FooterForFloa />
              </View>
            </Page>
          </Document>
        )
          .toBlob()
          .then(resolve)
          .catch(reject);
      });

      if (withWatermark === "view") {
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          '<iframe width="100%" height="100%" src="' +
            pdfDataUri +
            '"></iframe>'
        );

        setLoad(false);
      }
      if (withWatermark === "download") {
        if (hashValueData) {
          try {
            const fileName = `${
              withWatermark === "view" ? "FLOAINT-sample" : "FLOAINT"
            }_${applicationNo}.pdf`;
            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });

            const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

            const formData = new FormData();
            formData.append("file", pdfFile);

            const response = await axiosService.postFile(url, pdfFile, {});

            const byteCharacters = atob(response.data.data.body);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = urlBlob;
            a.download = "FLOAINT.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);
            setLoad(false);
          } catch (error) {
            console.error("Error:", error);
            setLoad(false);
          }
        }
        setLoad(false);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      setLoad(false);
    }
  };

  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
    </span>
  );
};

export default FloaInternationalDocument;
