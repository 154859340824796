import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import sha256 from "crypto-js/sha256";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { useEffect, useState } from "react";
import seal from "assets/images/Beta_seal.png";
import signature from "assets/images/Rahul_Trivedi_Signature.png";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";
import betalogo from "assets/images/icons/beta.svg";
import qrcode from "qrcode";
import { axiosService } from "services/axios.service";

const styles = StyleSheet.create({
  Document: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  qrCodeContainer: {
    backgroundColor: "violet",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
  },
  actualValue: {
    fontSize: 10,
    fontFamily: "Helvetica",
  },
  newText: {
    width: 150,
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    paddingVertical: 2,
    flexWrap: "wrap",
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 2.5,
  },
  labelText: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    textAlign: "left",
    paddingRight: 10,
  },
  colonText: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    paddingRight: 8,
  },
  valueText: {
    flex: 1,
    fontSize: 10,
    fontFamily: "Helvetica",
    textAlign: "left",
  },
  h1: {
    fontSize: 14,
    fontFamily: "Helvetica-Bold",
    textDecoration: "underline",
  },
  h5: {
    left: 42,
    right: 40,
    fontSize: 11,
    paddingRight: 60,
    top: 36,
  },
  register: {
    left: 42,
    right: 40,
    fontSize: 11,
    paddingRight: 60,
    lineHeight: 1.5,
    fontFamily: "Helvetica",
  },
  alignment: {
    left: 42,
    right: 40,
    paddingRight: 60,
  },
  flex: {
    flexDirection: "row",
    left: 40,
    right: 45,
    top: 40,
    justifyContent: "space-between",
  },
  folder: {
    fontSize: 11,
    marginLeft: 40,
    marginRight: 88,
    top: 98,
    fontFamily: "Helvetica-Bold",
  },
  link: {
    fontSize: 11,
    marginLeft: 43,
    top: 78,
    color: "#0563c1",
    textDecoration: "underline",
  },
  body: {
    top: 100,
  },
  bodycontainer: {
    marginLeft: 40,
    marginRight: 30,
  },
  sealImage: {
    width: 95,
    height: 95,
    position: "relative",
    top: 20,
  },
  signImage: {
    width: 130,
    height: 42,
    top: 5,
    left: 42,
    position: "relative",
  },
  qrCodeImage: {
    width: 80,
    top: 140,
    height: 82,
    position: "relative",
    zIndex: 1,
  },
  watermarkContainer: {
    position: "absolute",
    top: "40%",
    left: "30%",
    transform: "translate(-50%, -50%) rotate(-45deg)",
    opacity: 0.5,
  },
  watermarkText: {
    fontSize: 80,
    color: "gray",
  },
  footer: {
    position: "absolute",
    bottom: 40,
    left: 50,
    right: 40,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
  },
});

const Header = () => (
  <View style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
    <Image alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
  </View>
);

const Footer = () => (
  <View style={{ position: "absolute", top: "-60px", left: "-50px", right: 0 }}>
    <Image alt="img" src={footer} style={{ width: "110%", height: "80px" }} />
  </View>
);

const TravelSupport = ({ downloadType, pdfData }) => {
  const formatProgramName = (programName) => {
    return programName
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const applicationNo = pdfData?.programDetails[0]?.applicationNo;
  const currentDate = new Date();
  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 3);
  const formattedIssueDate = formatDate(currentDate);
  const Issue = formattedIssueDate;
  const Name =
    pdfData?.personalData?.firstName + " " + pdfData?.personalData?.lastName;
  const StartDate = pdfData?.programDetails[0]?.fromDate;

  const Program = pdfData?.programDetails[0]?.program
    ? formatProgramName(pdfData.programDetails[0].program)
    : "-";
  const Student = pdfData?.personalData?.studentId
    ? pdfData?.personalData?.studentId
    : "-";
  const DOB = pdfData?.personalData?.dateOfBirth;

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (downloadType === "view") {
      generatePdf("view");
    }
    if (downloadType === "download") {
      generatePdf("download");
    }
  }, [downloadType]);
  const generateQRCodeDataURL = async (data) => {
    try {
      const url = await qrcode.toDataURL(data);
      return url;
    } catch (error) {
      console.error("Error generating QR code:", error);
      return "";
    }
  };
  const [hashValueData, setHashValue] = useState(
    sha256(`${"Travel_support"}-${applicationNo}`).toString().substring(0, 20)
  );
  useEffect(() => {
    const uniqueValue = Math.random();
    const inputString = `Travel_support-${applicationNo}-${uniqueValue}`;

    const hashValue = sha256(inputString).toString().substring(0, 20);

    setHashValue(hashValue);
  }, [applicationNo]);
  const generatePdf = async (withWatermark) => {
    try {
      setLoad(true);
      const qrCodeDataURL = await generateQRCodeDataURL(
        `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`
      );
      const pdfBlob = await new Promise((resolve, reject) => {
        pdf(
          <Document>
            <Page size="A4" style={styles.page}>
              {withWatermark === "view" && (
                <View style={styles.watermarkContainer}>
                  <Text style={styles.watermarkText}>SAMPLE</Text>
                </View>
              )}
              <Header />
              <View style={styles.body}>
                <View>
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text style={styles.h1}>TRAVEL SUPPORT LETTER</Text>
                  </View>
                  <View style={styles.bodycontainer}>
                    <View style={{ paddingTop: 25 }}>
                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>Date:</Text>
                        <Text style={styles.valueText}>{Issue}</Text>
                      </View>
                    </View>
                    <Text style={[styles.boldText, { paddingTop: 25 }]}>
                      To Whom it may Concern:
                    </Text>
                    <View>
                      <Text
                        style={[
                          styles.actualValue,
                          { paddingTop: 20, lineHeight: 1.7 },
                        ]}
                      >
                        This is to confirm{" "}
                        <Text style={styles.boldText}>{Name}</Text>, Student ID:
                        <Text style={styles.boldText}>{Student}</Text> is
                        enrolled in the program - {Program} at Beta College of
                        Business and Technology from {StartDate}.
                      </Text>
                    </View>

                    <Text
                      style={[
                        styles.actualValue,
                        { paddingTop: 15, lineHeight: 1.7 },
                      ]}
                    >
                      {pdfData?.personalData?.sex === "FEMALE" ? "She" : "He"}{" "}
                      would be travelling on May 16, 2024 and will follow the
                      required guidelines from the date of arrival in Ontario,
                      Canada, as per provincial guidelines for the Ministry of
                      Colleges and Universities (MCU).
                    </Text>
                    <Text style={[styles.actualValue, { paddingTop: 15 }]}>
                      Feel free to contact the undersigned via email for any
                      questions.
                    </Text>
                  </View>
                </View>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <View>
                    <Text style={[styles.register, { paddingTop: 20 }]}>
                      Yours Sincerely,
                    </Text>

                    <Image style={[styles.signImage]} src={signature} />

                    <Text style={[styles.register, { paddingTop: 10 }]}>
                      For Beta College of Business and Technology
                    </Text>

                    <Text style={[styles.register, { paddingTop: 8 }]}>
                      Krunal Trivedi
                    </Text>
                    <Text style={[styles.register]}>
                      Registrar / Director of Admissions & Outreach
                    </Text>
                    <Text style={[styles.register]}>
                      Beta College of Business and Technology
                    </Text>
                    <Text style={[styles.register]}>
                      +1 905.497.9999 | +1 647.573.3660
                    </Text>
                    <Text style={[styles.register]}>
                      hello@mybeta.ca | info@betacollege.ca
                    </Text>
                    <Text style={[styles.register]}>
                      www.mybeta.ca | www.betacollege.ca
                    </Text>
                  </View>
                  <View>
                    <Image style={styles.sealImage} src={seal} />
                  </View>
                </View>

                <Text
                  style={[
                    styles.register,
                    { paddingTop: 15, paddingBottom: 15, lineHeight: 1.5 },
                  ]}
                >
                  I hereby acknowledge and confirm that I will abide by all
                  terms and conditions mentioned here including meeting all the
                  updated guidelines at the time of arrival.
                </Text>
                <View style={styles.alignment}>
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: "black",
                      paddingVertical: 7,
                      paddingHorizontal: 5,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingVertical: 5,
                        paddingRight: 60,
                      }}
                    >
                      <Text style={styles.boldText}>Student Name : </Text>
                      <View
                        style={{
                          marginTop: 10,
                          width: "200px",
                          borderBottom: "1px solid black",
                        }}
                      ></View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingVertical: 5,
                        paddingRight: 60,
                      }}
                    >
                      <Text style={styles.boldText}>Signature : </Text>
                      <View
                        style={{
                          marginTop: 10,
                          width: "200px",
                          borderBottom: "1px solid black",
                        }}
                      ></View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingVertical: 5,
                        paddingRight: 60,
                      }}
                    >
                      <Text style={styles.boldText}>Date : </Text>
                      <View
                        style={{
                          marginTop: 10,
                          width: "200px",
                          borderBottom: "1px solid black",
                        }}
                      ></View>
                    </View>
                  </View>
                </View>
              </View>
              {withWatermark === "download" && (
                <View>
                  <Image style={styles.qrCodeImage} src={qrCodeDataURL} />
                </View>
              )}

              <View style={styles.footer}>
                <View style={styles.qrCodeContainer}></View>
                <Footer />
              </View>
            </Page>
          </Document>
        )
          .toBlob()
          .then(resolve)
          .catch(reject);
      });

      if (withWatermark === "view") {
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          '<iframe width="100%" height="100%" src="' +
            pdfDataUri +
            '"></iframe>'
        );

        setLoad(false);
      }
      if (withWatermark === "download") {
        if (hashValueData) {
          try {
            const fileName = `${
              withWatermark === "view"
                ? "Travel_support-sample"
                : "Travel_support"
            }_${applicationNo}.pdf`;
            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });

            const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

            const formData = new FormData();
            formData.append("file", pdfFile);

            const response = await axiosService.postFile(url, pdfFile, {});

            const byteCharacters = atob(response.data.data.body);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = urlBlob;
            a.download = "Travel_support.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);
            setLoad(false);
          } catch (error) {
            console.error("Error:", error);
            setLoad(false);
          }
        }
        setLoad(false);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      setLoad(false);
    }
  };

  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
    </span>
  );
};

export default TravelSupport;
