import React, { useState } from "react";
import Modal from "react-modal";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import {
  STUDENT_PORTAL_DOCUMENT_ADMIN_URL,
  STUDENT_PORTAL_DOCUMENT_FACULTY_URL,
} from "modules/student-portal/constants/student-portal-urls";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";

function DocumentModal({ open, onClose, rowData }) {
  const [remarks, setRemarks] = useState("");
  const [approverDate, setApproverDate] = useState("");
  const [showError, setShowError] = useState(false);
  const [showErrorDate, setShowErrorDate] = useState(false);
  const userData = sessionstorageService.getUserData("userData");
  const role = userData?.role?.name;

  const handleUpdate = async (status, rowData) => {
    if (status === "REJECTED" && remarks === "") {
      setShowError(true);
    } else if (
      rowData?.document?.docName === "DLOA" &&
      status === "APPROVED" &&
      !approverDate
    ) {
      setShowErrorDate(true);
    } else if (role === "faculty") {
      let requestData = rowData;
      requestData.facultyStatus = status;
      requestData.rejectorFacultyComment = remarks;
      requestData.facultyApproved = {
        userId: userData?.userId,
      };
      requestData.isFacultyApproved = status === "APPROVED" ? true : false;
      requestData.approverDate = approverDate;
      requestData.facultyDate = new Date().toISOString();

      const response = await axiosService.put(
        `${STUDENT_PORTAL_DOCUMENT_FACULTY_URL.UPDATE_DOCUMENT_BY_FACULTY}`,
        requestData
      );
      if (response.status === 200) {
        if (response?.data?.data?.status === "REJECTED") {
          onClose();
          setRemarks("");
          setApproverDate("");
          toast.success("Document Request Rejected");
        } else {
          onClose();
          setRemarks("");
          setApproverDate("");
          setShowError(false);
          setShowErrorDate(false);
          toast.success("Document Request Approved");
        }
      }
    } else if (role === "super admin") {
      let requestData = rowData;
      requestData.status = status;
      requestData.rejectorComment = remarks;
      requestData.adminApproved = {
        userId: userData?.userId,
      };
      requestData.isAdminApproved = status === "APPROVED" ? true : false;
      requestData.approverDate = approverDate;
      requestData.adminDate = new Date().toISOString();

      const response = await axiosService.put(
        `${STUDENT_PORTAL_DOCUMENT_ADMIN_URL.UPDATE_DOCUMENT_BY_ADMIN}`,
        requestData
      );
      if (response.status === 200) {
        if (response?.data?.data?.status === "REJECTED") {
          onClose();
          setRemarks("");
          setApproverDate("");
          toast.success("Document Request Rejected");
        } else {
          onClose();
          setRemarks("");
          setApproverDate("");
          setShowError(false);
          setShowErrorDate(false);
          toast.success("Document Request Approved");
        }
      }
    }
  };

  return (
    <Modal className="crm-modal" isOpen={open}>
      <div className="modal-Con justify-content-lg-end pe-2">
        <div
          className="col-11 col-md-5 p-3"
          style={{ height: "auto", backgroundColor: "#333333" }}
        >
          <div className="student-portal-modal-header">
            <div>
              <span
                className="student-portal-modal-title"
                style={{ color: "#F69F3A" }}
              >
                Request for{" "}
              </span>{" "}
              <span className="" style={{ color: "#ffffff" }}>
                {rowData?.document?.docName}
              </span>
            </div>

            <IoClose
              color="#ffffff"
              size={"23px"}
              onClick={() => {
                setRemarks("");
                setApproverDate("");
                setShowError(false);
                setShowErrorDate(false);
                onClose();
              }}
            />
          </div>
          <div>
            <div className="d-flex flex-column gap-3 mt-2">
              <div className="d-flex flex-column">
                <span className="faculty-student-portal-service-popup-label">
                  Student Name
                </span>
                <span className="faculty-student-portal-service-popup-value">
                  {rowData?.user?.firstName} {rowData?.user?.lastName}
                </span>
              </div>

              <div className="d-flex flex-column">
                <span className="faculty-student-portal-service-popup-label">
                  Date of Request
                </span>
                <span className="faculty-student-portal-service-popup-value">
                  {rowData?.requestDate}
                </span>
              </div>
              {rowData?.document?.docName === "DLOA" && (
                <div className="d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-label">
                    Student Request Dates
                  </span>
                  <span className="faculty-student-portal-service-popup-value">
                    {rowData?.studentDate}
                  </span>
                </div>
              )}
              {rowData?.document?.docName === "DLOA" ? (
                <></>
              ) : (
                <div className="d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-label">
                    Upload Document
                  </span>
                  <a
                    href={rowData?.paymentReceipt}
                    target="_blank"
                    style={{ color: "#F69F3A" }}
                    className="faculty-student-portal-service-popup-value text-decoration-underline"
                  >
                    {rowData?.paymentReceiptName}
                  </a>
                </div>
              )}
              {rowData?.enrollmentReceiptUrl ? (
                <div className="d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-label">
                    Contract
                  </span>

                  <a
                    href={rowData?.enrollmentReceiptUrl}
                    target="_blank"
                    style={{ color: "#F69F3A" }}
                    className="faculty-student-portal-service-popup-value text-decoration-underline"
                  >
                    {rowData?.enrollmentReceiptName}
                  </a>
                </div>
              ) : null}
              {rowData?.comments && (
                <div className="d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-label">
                    Comments/Queries
                  </span>
                  <input
                    className="faculty-student-portal-service-popup-input"
                    name="comments"
                    value={rowData.comments}
                    disabled
                    readOnly
                  />
                </div>
              )}
            </div>
            {rowData?.document?.docName === "DLOA" && (
              <div className="mt-2 pt-2" style={{ position: "relative" }}>
                <label className="faculty-student-portal-service-popup-value">
                  Approver Date
                </label>
                <input
                  className="faculty-student-portal-service-popup-input mt-1"
                  name="approverDate"
                  type="date"
                  style={{ width: "100%" }}
                  value={approverDate}
                  onChange={(e) => setApproverDate(e.target.value)}
                />
                {showErrorDate && approverDate === "" && (
                  <span className="error-message-student-portal ">
                    Approver Date is mandatory
                  </span>
                )}
              </div>
            )}

            <div className="mt-2 pt-2" style={{ position: "relative" }}>
              <label className="faculty-student-portal-service-popup-value">
                Remarks
              </label>
              <input
                className="faculty-student-portal-service-popup-input"
                name="remarks"
                value={remarks}
                onChange={(event) => setRemarks(event.target.value)}
              />
              {showError && remarks === "" && (
                <span className="error-message-student-portal ">
                  Remark is mandatory
                </span>
              )}
            </div>
          </div>

          <div className=" d-flex gap-3 mt-4">
            <button
              className="student-portal-cancel-button"
              type="button"
              onClick={() => {
                setRemarks("");
                setApproverDate("");
                setShowError(false);
                setShowErrorDate(false);
                onClose();
              }}
            >
              Cancel
            </button>
            {role === "super admin" &&
              (rowData?.status === "OPEN" || rowData?.status === "PENDING") && (
                <button
                  className="student-portal-gray-save-head-button"
                  type="submit"
                  onClick={() => handleUpdate("REJECTED", rowData)}
                >
                  Reject
                </button>
              )}
            {role === "faculty" &&
              (rowData?.facultyStatus === "OPEN" ||
                rowData?.facultyStatus === "PENDING" ||
                rowData?.facultyStatus === null) && (
                <button
                  className="student-portal-gray-save-head-button"
                  type="submit"
                  onClick={() => handleUpdate("REJECTED", rowData)}
                >
                  Reject
                </button>
              )}
            {role === "super admin" &&
              (rowData?.status === "OPEN" || rowData?.status === "PENDING") && (
                <button
                  className="student-portal-submit-button"
                  type="submit"
                  onClick={() => handleUpdate("APPROVED", rowData)}
                >
                  Approve
                </button>
              )}
            {role === "faculty" &&
              (rowData?.facultyStatus === "OPEN" ||
                rowData?.facultyStatus === "PENDING" ||
                rowData?.facultyStatus === null) && (
                <button
                  className="student-portal-submit-button"
                  type="submit"
                  onClick={() => handleUpdate("APPROVED", rowData)}
                >
                  Approve
                </button>
              )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default DocumentModal;
