import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import sha256 from "crypto-js/sha256";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { useEffect, useState } from "react";
import seal from "assets/images/Beta_seal.png";
import signature from "assets/images/Monika_sign.png";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";
import betalogo from "assets/images/icons/beta.svg";
import qrcode from "qrcode";
import { axiosService } from "services/axios.service";

const styles = StyleSheet.create({
  Document: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  qrCodeContainer: {
    backgroundColor: "violet",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
  },
  actualValue: {
    fontSize: 10,
    fontFamily: "Helvetica",
  },
  newText: {
    width: 150,
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    paddingVertical: 2,
    flexWrap: "wrap",
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 2.5,
  },
  labelText: {
    width: 140,
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    textAlign: "left",
    paddingRight: 10,
  },
  colonText: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    paddingRight: 8,
  },
  valueText: {
    flex: 1,
    fontSize: 10,
    fontFamily: "Helvetica",
    textAlign: "left",
  },
  h1: {
    fontSize: 14,
    fontFamily: "Helvetica-Bold",
    textDecoration: "underline",
  },
  h5: {
    left: 42,
    right: 40,
    fontSize: 11,
    paddingRight: 60,
    top: 36,
  },
  register: {
    left: 42,
    right: 40,
    fontSize: 11,
    paddingRight: 60,
    top: 46,
  },
  flex: {
    flexDirection: "row",
    left: 40,
    right: 45,
    top: 40,
    justifyContent: "space-between",
  },
  folder: {
    fontSize: 11,
    marginLeft: 40,
    marginRight: 88,
    top: 98,
    fontFamily: "Helvetica-Bold",
  },
  link: {
    fontSize: 11,
    marginLeft: 43,
    top: 78,
    color: "#0563c1",
    textDecoration: "underline",
  },
  body: {
    top: 100,
  },
  bodycontainer: {
    marginLeft: 40,
    marginRight: 30,
  },
  sealImage: {
    width: 80,
    height: 80,
    position: "relative",
    zIndex: 1,
    top: 68,
  },
  signImage: {
    width: 130,
    height: 40,
    position: "relative",
    zIndex: 1,
    top: 65,
    left: 45,
  },
  qrCodeImage: {
    width: 80,
    top: 235,
    height: 82,
    position: "relative",
    zIndex: 1,
  },
  watermarkContainer: {
    position: "absolute",
    top: "40%",
    left: "30%",
    transform: "translate(-50%, -50%) rotate(-45deg)",
    opacity: 0.5,
  },
  watermarkText: {
    fontSize: 80,
    color: "gray",
  },
  footer: {
    position: "absolute",
    bottom: 40,
    left: 50,
    right: 40,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
  },
  otherInfo: {
    fontSize: 11,
    top: 15,
    fontFamily: "Helvetica-Bold",
    marginBottom: 1,
  },
  dot: {
    fontSize: 11,
    marginLeft: 15,
    top: 30,
    marginBottom: 5,
    fontFamily: "Helvetica",
  },
});

const Header = () => (
  <View style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
    <Image alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
  </View>
);

const Footer = () => (
  <View style={{ position: "absolute", top: "-60px", left: "-50px", right: 0 }}>
    <Image alt="img" src={footer} style={{ width: "110%", height: "80px" }} />
  </View>
);

const OfficialReceiptProvisional = ({ downloadType, pdfData, Data }) => {
  const formatProgramName = (programName) => {
    return programName
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const applicationNo = pdfData
    ? pdfData?.programDetails[0]?.applicationNo
    : Data?.master?.admissionManagement?.programDetails[0]?.applicationNo;
  const currentDate = new Date();
  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 3);
  const formattedIssueDate = formatDate(currentDate);
  const Issue = formattedIssueDate;
  const Name = pdfData
    ? pdfData?.personalData?.firstName + " " + pdfData?.personalData?.lastName
    : Data?.master?.admissionManagement?.personalData?.firstName +
      " " +
      Data?.master?.admissionManagement?.personalData?.lastName;
  const StartDate = pdfData
    ? pdfData?.programDetails[0]?.fromDate
    : Data?.master?.admissionManagement?.programDetails[0].fromDate;

  const Program = pdfData?.programDetails[0]?.program
    ? formatProgramName(pdfData.programDetails[0].program)
    : formatProgramName(
        Data?.master?.admissionManagement?.programDetails[0].program
      );
  const Student = pdfData?.personalData?.studentId
    ? pdfData?.personalData?.studentId
    : Data?.master?.admissionManagement?.personalData?.studentId;

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (downloadType === "view") {
      generatePdf("view");
    }
    if (downloadType === "download") {
      generatePdf("download");
    }
  }, [downloadType]);
  const generateQRCodeDataURL = async (data) => {
    try {
      const url = await qrcode.toDataURL(data);
      return url;
    } catch (error) {
      console.error("Error generating QR code:", error);
      return "";
    }
  };
  const [hashValueData, setHashValue] = useState(
    sha256(`${"Fee_Receipt_Pro"}-${applicationNo}`).toString().substring(0, 20)
  );
  useEffect(() => {
    const uniqueValue = Math.random();
    const inputString = `Fee_Receipt_Pro-${applicationNo}-${uniqueValue}`;

    const hashValue = sha256(inputString).toString().substring(0, 20);

    setHashValue(hashValue);
  }, [applicationNo]);
  const generatePdf = async (withWatermark) => {
    try {
      setLoad(true);
      const qrCodeDataURL = await generateQRCodeDataURL(
        `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`
      );
      const pdfBlob = await new Promise((resolve, reject) => {
        pdf(
          <Document>
            <Page size="A4" style={styles.page}>
              {withWatermark === "view" && (
                <View style={styles.watermarkContainer}>
                  <Text style={styles.watermarkText}>SAMPLE</Text>
                </View>
              )}
              <Header />
              <View style={styles.body}>
                <View style={styles.alignment}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginHorizontal: 40,
                      paddingBottom: 15,
                    }}
                  >
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text style={styles.boldText}>Date:</Text>
                      <Text style={styles.actualValue}>
                        {formattedIssueDate}
                      </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text style={styles.boldText}> Receipt No.:</Text>
                      <Text style={styles.actualValue}>-</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text style={styles.h1}>
                      OFFICIAL FEE RECEIPT (PROVISIONAL)
                    </Text>
                  </View>
                  <View style={styles.bodycontainer}>
                    <View style={{ paddingTop: 25 }}>
                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>Student Name</Text>
                        <Text style={styles.colonText}>:</Text>
                        <Text style={styles.valueText}>{Name}</Text>
                      </View>
                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>Student Id</Text>
                        <Text style={styles.colonText}>:</Text>
                        <Text style={styles.valueText}>{Student}</Text>
                      </View>

                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>Course</Text>
                        <Text style={styles.colonText}>:</Text>
                        <Text style={styles.valueText}>{Program}</Text>
                      </View>
                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>Start Date </Text>
                        <Text style={styles.colonText}>:</Text>
                        <Text style={styles.valueText}>{StartDate}</Text>
                      </View>
                    </View>
                    <View style={{ paddingTop: 20 }}>
                      <Text
                        style={[
                          styles.actualValue,
                          { textDecoration: "underline", fontSize: 13 },
                        ]}
                      >
                        {Data?.invoiceType
                          ? formatProgramName(Data?.invoiceType)
                          : "Tuition Fee"}{" "}
                        towards the above program:
                      </Text>
                    </View>
                    <View style={{ paddingTop: 20 }}>
                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>Total Fees Paid </Text>
                        <Text style={styles.colonText}>:</Text>
                        <Text style={styles.valueText}>
                          CAD {Data?.invoiceAmount ? Data?.invoiceAmount : "-"}
                        </Text>
                      </View>
                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>Fee Type</Text>
                        <Text style={styles.colonText}>:</Text>
                        <Text style={styles.valueText}>
                          {Data?.invoiceType
                            ? formatProgramName(Data?.invoiceType)
                            : "-"}
                        </Text>
                      </View>
                      <View style={styles.textContainer}>
                        <Text style={styles.labelText}>Mode of Payment</Text>
                        <Text style={styles.colonText}>:</Text>
                        <Text style={styles.valueText}>
                          {Data?.paymentMode ? Data?.paymentMode : "-"}
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text
                        style={[
                          styles.actualValue,
                          { paddingTop: 20, lineHeight: 1.7 },
                        ]}
                      >
                        For any questions, please feel free to contact Accounts
                        Officer at +1(905) 497 9999 or email{" "}
                        <Text style={styles.link}>finance@mybeta.ca</Text>.
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <View>
                    <Text style={[styles.h5, { top: 55 }]}>
                      For Beta College of Business and Technology:
                    </Text>
                    <Image style={styles.signImage} src={signature} />

                    <Text style={[styles.h5, { top: 80 }]}>Monica Singh</Text>

                    <Text style={[styles.h5, { top: 83 }]}>
                      Sr. Accounts Officer
                    </Text>
                  </View>
                  <View>
                    <Image style={styles.sealImage} src={seal} />
                  </View>
                </View>
                <View style={[styles.register, { paddingTop: 33 }]}>
                  <Text style={styles.otherInfo}>Important Information:</Text>
                  <Text style={styles.dot}>
                    •{"  "} All fees and payments are subject to Terms and
                    Conditions and actual realization. Claims will only be
                    entertained once the fees/funds are deposited in the college
                    bank account/financial institution. Transfer proof from a
                    financial institution or Official Bank receipt is mandatory
                    to produce to admit any application for a refund.
                  </Text>
                  <Text style={styles.dot}>
                    •{"  "} This document can be verified by emailing
                    admin@betacollege.ca / hello@mybeta.ca
                  </Text>
                </View>
              </View>
              {withWatermark === "download" && (
                <View>
                  <Image style={styles.qrCodeImage} src={qrCodeDataURL} />
                </View>
              )}

              <View style={styles.footer}>
                <View style={styles.qrCodeContainer}></View>
                <Footer />
              </View>
            </Page>
          </Document>
        )
          .toBlob()
          .then(resolve)
          .catch(reject);
      });

      if (withWatermark === "view") {
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          '<iframe width="100%" height="100%" src="' +
            pdfDataUri +
            '"></iframe>'
        );

        setLoad(false);
      }
      if (withWatermark === "download") {
        if (hashValueData) {
          try {
            const fileName = `${
              withWatermark === "view"
                ? "Fee_Receipt_Pro-sample"
                : "Fee_Receipt_Pro"
            }_${applicationNo}.pdf`;
            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });

            const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

            const formData = new FormData();
            formData.append("file", pdfFile);

            const response = await axiosService.postFile(url, pdfFile, {});

            const byteCharacters = atob(response.data.data.body);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = urlBlob;
            a.download = "Fee_Receipt_Pro.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);
            setLoad(false);
          } catch (error) {
            console.error("Error:", error);
            setLoad(false);
          }
        }
        setLoad(false);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      setLoad(false);
    }
  };

  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
    </span>
  );
};

export default OfficialReceiptProvisional;
