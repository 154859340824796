import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import sha256 from "crypto-js/sha256";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import { useEffect, useState } from "react";
import seal from "assets/images/Seal_Gold.png";
import pooja_sign from "assets/images/Pooja_Signature.png";
import richa_sign from "assets/images/Richa_signature.png";
import signature from "assets/images/Rahul_Trivedi_Signature.png";
import beta from "assets/images/header2_img.png";
import footer from "assets/images/header_img_cer.png";
import betalogo from "assets/images/icons/beta.svg";
import qrcode from "qrcode";
import { axiosService } from "services/axios.service";

const styles = StyleSheet.create({
  Document: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  qrCodeContainer: {
    backgroundColor: "violet",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
  },
  actualValue: {
    fontSize: 12,
    fontFamily: "Helvetica",
    right: 4,
    paddingTop: 1,
  },

  qrCodeImage: {
    width: 75,
    bottom: 20,
    height: 73,
    position: "relative",
    zIndex: 1,
    left: 43,
  },
  watermarkContainer: {
    position: "absolute",
    top: "40%",
    left: "30%",
    transform: "translate(-50%, -50%) rotate(-45deg)",
    opacity: 0.5,
  },
  watermarkText: {
    fontSize: 80,
    color: "gray",
  },
  footerText: {
    fontSize: 14,
    fontFamily: "Helvetica",
    textAlign: "center",
  },
  headerText: {
    fontFamily: "Helvetica",
    fontSize: 16,
    lineHeight: 1.4,
    textAlign: "center",
  },
  nameText: {
    fontFamily: "Helvetica",
    fontSize: 23,
    textAlign: "center",
  },
});

const Header = () => (
  <View
    style={{
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: 90,
    }}
  >
    <View style={{ width: 220, paddingRight: 45 }}>
      <Image
        alt="img"
        source={footer}
        style={{ width: "100%", height: "100%" }}
      />
    </View>
    <View
      style={{
        width: 1,
        backgroundColor: "#000",
        height: "100%",
      }}
    />
    <View style={{ width: 350, paddingLeft: 70 }}>
      <Image
        alt="img"
        source={beta}
        style={{ width: "100%", height: "100%" }}
      />
    </View>
    <View style={{ flex: 1 }} />
  </View>
);
const Footer = ({ issue }) => (
  <View
    style={{
      top: 60,
      display: "flex",
      flexDirection: "row",
      width: "100%",
      paddingTop: 15,
      justifyContent: "space-between",
    }}
  >
    <View style={{ width: 160 }}>
      <Text style={styles.footerText}>UNDER THE SEAL OF THE COLLEGE ON </Text>
      <Text style={[styles.footerText]}>{issue}</Text>
    </View>
    <View style={{}}>
      <Image alt="img" source={seal} style={{ width: 130, height: 130 }} />
    </View>
    <View style={{ width: 300 }}>
      <View
        style={{
          borderBottomWidth: 1,
          borderColor: "#000",
        }}
      >
        <Image
          alt="img"
          source={richa_sign}
          style={{ width: 125, height: 25 }}
        />
      </View>
      <Text style={[styles.actualValue, { textAlign: "right" }]}>Dean</Text>
      <View
        style={{
          borderBottomWidth: 1,
          borderColor: "#000",
          textAlign: "center",
        }}
      >
        <Image
          alt="img"
          source={pooja_sign}
          style={{ width: 125, height: 25 }}
        />
      </View>
      <Text style={[styles.actualValue, { textAlign: "right" }]}>
        Chief Executive
      </Text>
      <View style={{ borderBottomWidth: 1, borderColor: "#000" }}>
        <Image
          alt="img"
          source={signature}
          style={{ width: 125, height: 25 }}
        />
      </View>
      <Text style={[styles.actualValue, { textAlign: "right" }]}>
        Registrar
      </Text>
    </View>
  </View>
);

const FinalCertificate = ({ downloadType, pdfData, Data }) => {
  const formatProgramName = (programName) => {
    return programName
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const applicationNo = pdfData?.programDetails[0]?.applicationNo;
  const currentDate = new Date();
  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 3);
  const formattedIssueDate = formatDate(currentDate);
  const Issue = formattedIssueDate;
  const Name =
    pdfData?.personalData?.firstName + " " + pdfData?.personalData?.lastName;

  const Program = formatProgramName(pdfData.programDetails[0].program);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (downloadType === "view") {
      generatePdf("view");
    }
    if (downloadType === "download") {
      generatePdf("download");
    }
  }, [downloadType]);
  const generateQRCodeDataURL = async (data) => {
    try {
      const url = await qrcode.toDataURL(data);
      return url;
    } catch (error) {
      console.error("Error generating QR code:", error);
      return "";
    }
  };
  const [hashValueData, setHashValue] = useState(
    sha256(`${"Final_Certificate"}-${applicationNo}`)
      .toString()
      .substring(0, 20)
  );
  useEffect(() => {
    const uniqueValue = Math.random();
    const inputString = `Final_Certificate-${applicationNo}-${uniqueValue}`;

    const hashValue = sha256(inputString).toString().substring(0, 20);

    setHashValue(hashValue);
  }, [applicationNo]);
  const generatePdf = async (withWatermark) => {
    try {
      setLoad(true);
      const qrCodeDataURL = await generateQRCodeDataURL(
        `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`
      );
      const pdfBlob = await new Promise((resolve, reject) => {
        pdf(
          <Document>
            <Page size={[850, 620]} style={styles.page}>
              {withWatermark === "view" && (
                <View style={styles.watermarkContainer}>
                  <Text style={styles.watermarkText}>SAMPLE</Text>
                </View>
              )}
              <View
                style={{
                  borderWidth: 3,
                  borderColor: "#000",
                  flex: 1,
                  paddingVertical: 15,
                  paddingHorizontal: 25,
                }}
              >
                <View style={styles.body}>
                  <Header />
                  <View>
                    <Text style={[styles.headerText, { paddingTop: 20 }]}>
                      Upon the recommendation of the Faculty and the Board of
                      Directors, Beta College of Business and Technology, a
                      registered career college under the Ontario Career
                      Colleges Act, 2005, does hereby confer upon
                    </Text>
                    <Text style={[styles.nameText, { paddingTop: 15 }]}>
                      {Name}
                    </Text>
                    <Text
                      style={[
                        styles.headerText,
                        { textAlign: "center", paddingTop: 15 },
                      ]}
                    >
                      the Diploma in
                    </Text>
                    <Text style={[styles.nameText, { paddingTop: 15 }]}>
                      {Program}
                    </Text>
                  </View>
                </View>
                <Footer issue={Issue} />
                {withWatermark === "download" && (
                  <View>
                    <Image style={styles.qrCodeImage} src={qrCodeDataURL} />
                  </View>
                )}
              </View>
            </Page>
          </Document>
        )
          .toBlob()
          .then(resolve)
          .catch(reject);
      });

      if (withWatermark === "view") {
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        const newWindow = window?.open();
        newWindow?.document?.write(
          '<iframe width="100%" height="100%" src="' +
            pdfDataUri +
            '"></iframe>'
        );

        setLoad(false);
      }
      if (withWatermark === "download") {
        if (hashValueData) {
          try {
            const fileName = `${
              withWatermark === "view"
                ? "Final_Certificate-sample"
                : "Final_Certificate"
            }_${applicationNo}.pdf`;
            const pdfFile = new File([pdfBlob], fileName, {
              type: "application/pdf",
            });

            const url = `${DOCUMENT_URLS.RECEIPT_URL}?hashValue=${hashValueData}&applicationNo=${applicationNo}`;

            const formData = new FormData();
            formData.append("file", pdfFile);

            const response = await axiosService.postFile(url, pdfFile, {});

            const byteCharacters = atob(response.data.data.body);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = urlBlob;
            a.download = "Final_Certificate.pdf";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);
            setLoad(false);
          } catch (error) {
            console.error("Error:", error);
            setLoad(false);
          }
        }
        setLoad(false);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      setLoad(false);
    }
  };

  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
    </span>
  );
};

export default FinalCertificate;
