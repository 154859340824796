import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { CLASS_URLS } from "../constants/class-url";
import { ThemeContext } from "ThemeContext";
import { FaPen, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function ListClass() {
  const location = useLocation();
  const navigate = useNavigate();
  const [classData, setClassData] = useState("");
  const { theme, toggleTheme } = useContext(ThemeContext);

  function getData() {
    axiosService.get(`${CLASS_URLS.GET_ALL}`).then((response) => {
      if (response.status === 200) {
        const responseData = response?.data?.data;
        setClassData(responseData);
      }
    });
  }

  useEffect(() => {
    // axiosService.get(`${CLASS_URLS.GET_ALL}`).then((response) => {
    //   if (response.status === 200) {
    //     const responseData = response?.data?.data;
    //     setClassData(responseData);
    //   }
    // });
    getData();
  }, [location.pathname]);

  function deleteContactList(rowData) {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this Class Schedule?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .delete(`${CLASS_URLS.DELETE_CLASS}${rowData?.scheduleId}`)
          .then((response) => {
            if (response.status === 200) {
              getData();
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message.substring(7));
          });
      }
    });
  }

  // const onRowClicked = (event) => {
  //   console.log(event?.data, "eventttt");
  //   navigate("/timetable-management/add-class", {
  //     state: {
  //       data: {
  //         Data: event.data,
  //       },
  //     },
  //   });
  // };

  const onCellClicked = (event) => {
    if (event.colDef.field === "Actions") {
      return;
    }
    navigate("/timetable-management/add-class", {
      state: {
        data: {
          Data: event.data,
        },
      },
    });
  };

  const [columnDefs] = useState([
    {
      headerName: "Class Name",
      field: "module.module.moduleName",
      cellStyle: { "text-decoration": "underline" },
    },
    {
      headerName: "Faculty Assigned",
      field: "facultyAssigned.firstName",
    },
    {
      headerName: "Campus",
      field: "module.programFeeMaster.campus",
    },
    {
      headerName: "Facility ID",
      field: "resource.resourceName",
    },
    {
      headerName: "Shift",
      field: "shift",
    },
    {
      headerName: "Timing",
      valueGetter: mergeStartEndTime,
    },
    {
      headerName: "Start Date",
      field: "startDate",
    },
    {
      headerName: "End Date",
      field: "endDate",
    },
    {
      field: "Actions",
      cellRenderer: function (params) {
        const rowData = params.data;

        return (
          <div>
            <FaTrash
              style={{
                marginRight: "20px",
                height: "1em",
                width: "1em",
              }}
              onClick={() => {
                deleteContactList(rowData);
              }}
            />
          </div>
        );
      },
    },
  ]);
  function mergeStartEndTime(params) {
    const startTime = params.data.startTime;
    const endTime = params.data.endTime;
    return `${startTime} - ${endTime}`;
  }
  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container flex-sm-row gap-2 ms-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">All Class Schedules </span>
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="sps-fv-top-count sps-fv-hi">
            <div className="d-flex align-items-center gap-3">
              <button
                className="communication-save-head-button"
                onClick={() => {
                  navigate("/timetable-management/add-class");
                }}
              >
                Add a Class
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={classData}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onCellClicked={onCellClicked}
        />
      </div>
    </div>
  );
}

export default ListClass;
