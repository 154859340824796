import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { PROGRAM_URLS } from "modules/academic-management/constants/program-constants-urls";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import "./ListModules.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ThemeContext } from "ThemeContext";

function ListModules() {
  const location = useLocation();
  const navigate = useNavigate();
  const [completedApplicationData, setCompletedApplicationData] = useState([]);
  const [totalModulesCount, setTotalModulesCount] = useState("");

  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    axiosService.get(`${PROGRAM_URLS.GET_ALL_MODULES}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data.modules;
        const moduleCount = response.data.data.totalModules;
        setCompletedApplicationData(responseData);
        setTotalModulesCount(moduleCount);
      }
    });
  }, [location.pathname]);

  const onRowClicked = (event) => {
    navigate("/academics-management/view-modules", {
      state: {
        data: {
          Data: event.data,
        },
      },
    });
  };
  const [selectedModule, setSelectedModule] = useState("");

  function onSelectionChanged(params) {
    // var selectedNodes = gridOptions?.api?.getSelectedNodes();
    const selectedNodes = params.api.getSelectedNodes();
    var selectedData = selectedNodes?.map((node) => node.data);

    if (selectedData?.length > 0) {
      setSelectedModule(selectedData[0]);
    } else {
      setSelectedModule(null);
    }
  }
  const [columnDefs] = useState([
    // {
    //   headerName: "",
    //   field: "select",
    //   checkboxSelection: true,
    //   headerCheckboxSelection: true,
    //   width: 50,
    //   floatingFilter: false,
    //   filter: false,
    // },
    {
      headerName: "Module Code",
      field: "moduleCode",
    },
    {
      headerName: "Module Name",
      field: "moduleName",
      cellStyle: { textDecoration: "underline" },
    },
    {
      headerName: "Faculty Assigned",
      field: "facultyAssigned.firstName",
      cellStyle: { textDecoration: "underline" },
    },
    {
      headerName: "Total Hours",
      field: "totalHours",
    },
    {
      headerName: "Beta Credits",
      field: "betaCredits",
    },
    {
      headerName: "Level",
      field: "level",
    },
    {
      headerName: "Status",
      field: "status",
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    // checkboxSelection: function (params) {
    //   return (
    //     params.columnApi.getAllDisplayedColumns().indexOf(params.column) !== 0
    //   );
    // },
    checkboxSelection: true,
    onSelectionChanged: onSelectionChanged,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const handleAlert = () => {
    Swal.fire({
      title: "Confirm Delete",
      html: "Are you sure you want to Delete the Module?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteModule(selectedModule.moduleId);
      }
    });
  };
  const deleteModule = (moduleId) => {
    axiosService
      .delete(`${PROGRAM_URLS.DELETE_MODULES_BY_MODULE_ID}${moduleId}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Module Deleted Successfully!");
          axiosService
            .get(`${PROGRAM_URLS.GET_ALL_MODULES}`)
            .then((response) => {
              if (response.status === 200) {
                const responseData = response.data.data.modules;
                const moduleCount = response.data.data.totalModules;
                setCompletedApplicationData(responseData);
                setTotalModulesCount(moduleCount);
              }
            });
          setSelectedModule(null);
        }
      })
      .catch((error) => {
        console.error("Error deleting module:", error);
      });
  };

  return (
    <div className="view-leads-table-container">
      <div className="sticky-header lead-table-head-container align-items-center justify-content-sm-start flex-row flex-wrap gap-2">
        <h6 className="lead-table-title-text">All Modules </h6>

        <div className="ms-sm-auto academic-all-program-module-count border-0 p-0">
          Total Modules<span>{totalModulesCount}</span>
        </div>

        <div className="d-flex align-items-center flex-wrap">
          <div className="d-flex align-items-center gap-3">
            <button
              className="communication-save-head-button"
              onClick={() => {
                navigate("/academics-management/add-module");
              }}
            >
              Add Modules
            </button>
            {/* <button
              className={`lead-table-head-button m-0 ${
                !selectedModule && "user-head-button-disabled"
              }`}
              disabled={!selectedModule}
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={selectedModule ? handleAlert : undefined}
            >
              Delete Module
            </button> */}
          </div>
        </div>
      </div>

      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={completedApplicationData}
          rowSelection="single"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
}

export default ListModules;
